import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { Observable } from 'rxjs';
import { IDsBreadcrumbItem } from '@levelaccess/design-system';
import { map } from 'rxjs/operators';

import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { IBreadcrumb } from '../breadcrumbs/breadcrumbs.shared';

// WARNING: this recursively adds breadcrumbs to all children routes

@Component({
  selector: 'app-breadcrumb-wrapper',
  templateUrl: './breadcrumb-wrapper.component.html',
})
export class BreadcrumbWrapperComponent implements OnInit {
  public breadcrumbs$: Observable<IDsBreadcrumbItem[]>;

  public static wrap(route: Route): Route {
    return {
      path: '',
      component: BreadcrumbWrapperComponent,
      children: [route],
    };
  }

  constructor(private breadcrumbsService: BreadcrumbsService) {}

  public ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbsService.crumbs$.pipe(
      map((breadcrumbs: IBreadcrumb[]): IDsBreadcrumbItem[] => {
        return breadcrumbs.map(
          (breadcrumb: IBreadcrumb): IDsBreadcrumbItem => ({
            label: breadcrumb.textTranslated ?? breadcrumb.text,
            rtLink: breadcrumb.path,
            queryParams: breadcrumb.queryParams,
          }),
        );
      }),
    );
  }
}
