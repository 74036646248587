import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import { Router } from '@angular/router';

import { IGlobalResponse, IUserClient, IUserDeactivateAccount, IUserProfileFormData } from '../interfaces/user.interface';
import { AuthService } from './auth.service';
import { BrowserLocalStorageService } from './browser-local-storage.service';
import {
  IBulkEmailUpdateResponse,
  IGetUsersResponseData,
  IMfaLoginRequest,
  IMfaRegistrationQrResponse,
  IMfaStatusResponse,
  IMfaTokenVerificationRequest,
  IUser,
  IUserEmailResponse,
  IUserPasswordSettings,
  IUserPublicResponse,
  IUserServerResponse,
} from '../../../shared/interfaces/user.interface';
import { UserRestAPI } from './rest/user.api';
import { AdminRestAPI } from './rest/admin.api';
import { IHttpQueryOptions } from '../../../shared/interfaces/http.query.interface';
import { $workspace, workspaceStatus, WorkspaceTypes } from '../../../shared/constants/workspace';
import { $userPreference, userPreferenceScope, userPreferenceScopeParam } from '../../../shared/constants/user-preferences';
import { NotificationService } from './notification.service';
import { NotificationPosition } from '../models/notification.model';
import { $user } from '../../../shared/constants/user';
import { IApplicationRole } from '../../../shared/interfaces/application-role.interface';
import { AclRoles } from '../../../shared/constants/acl';
import { $applicationRole } from '../../../shared/constants/application-role.constants';
import { IWorkspaceClient, IWorkspaceSettings } from '../../../shared/interfaces/workspace.interface';
import { IDigitalPropertyListItem, IWorkspaceIDItem } from '../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { IUserSignupResponse, IUserSignUpRequest } from '../../../shared/interfaces/user-authentication.interface';
import { EmptyObject } from '../../../shared/interfaces/empty-object.interface';
import { IWorkspaceRolesResponse } from '../../../shared/interfaces/acl.interface';
import { IUserPreference, IUserPreferencesServerResponse } from '../../../shared/interfaces/user-preference.interface';
import { errorMessagesNames } from '../../../shared/constants/errors';
import { GlobalResponse } from '../../../shared/guards/global-response';
import { Api } from '../../../shared/constants/api';
import { IImageSrcResponse } from '../../../shared/interfaces/common.interface';
import { AppConfigService } from './app-config.service';
import { ErrorMessageService } from './error-message.service';
import { ITenantInfo } from '../../../shared/interfaces/tenant.interface';
import { TenantService } from './tenant.service';
import { AngularUtility } from '../utility/angular.utility';
import { CommonUtility } from '../utility/common.utility';
import { ISecurityGroup } from '../../../shared/interfaces/security-group.interface';
import { $securityGroup } from '../../../shared/constants/security-group';
import { IRecentUserWorkspace } from '../../../shared/interfaces/recent-resources.interface';
import { AccessibilityAuditToolNames } from '../../../shared/constants/audit-tool';

export const defaultAvatar: string =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAmVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAjHWqVAAAAMnRSTlMANMz9L+0CJwHk2SSudmdMOAnq0pwe8cl/bfj1lnlFKxpUPQW/s6KJEbaNhsSrkHJfFf6uiA8AAAJ6SURBVHja7drZcqMwEAXQa7ODbQwYr/GexM6e3P//uHmYl5mqgGmnG9dMcX5At0oS3UhCp9PpdDo/kZVb100y3EDoLh77Hn+LBqMzWtW7i/i35aREa9IZv7FaoB3bV1Y4oQ3DJSvNYe74xjqfMJbds9b09W3twtCADcwOsDJiI87Jh4lkxYYmIQwcZ2xsDgMjNueMoS6MKdAPoW1DkRTanihyD2WlQxGnhK5PCi2ga0KhB+iKKeRB1dmhVAlNG4ptoGlPsZHld7j9ejCk2DM0nSh2B013tw7wTrH3W6+BNTSlFNtDU0mxAKoiCnkhbrsNHqFrI18CuvyIIt4Ryl4ocoIy16OI50JVWFCoCKFpTLExNI3+wYZEN0BKsRSadhTbQVVMoRi65vKeVNfXlCLTLyjbOxRw9lC3mTWO4Mw2sPAg+DM2sRb0oyYCQTto417jfMi+JjpjmHkUtKMmdlNeNN3B0JAXDWHJf+IFTz5MnWPWis8wFnis4QUwN65J4I3RgiRihShBK/qs0Me3ugBdgC7Afxcg81jBy9CGoaAUW3BXrLRyYa6Xs0begy1/sWSt5cKHnTAteFGRhrBRfsRsJP4ooS5ZzygwGyZQ5M4LihVzFxr8w3PMK8XPB/+Ho6eTnD+ST9LrMyQvERVELwmu4Q4cKnEGLqSC+uHlEQJI+OsllS2HvmDy+zTQT9CQm9NE7qKRw5RGpgc0EKxoZhXgoqygoSLTfa9jcKk/prGx4DjWxAC1tg6NOVvBCjAxQY0wp7k8rJsBtqBuDhZsweL6l8v2+yBiCyJUOjpsgXMU3I+a2KFKwFYEqOKyFS6qZL1WZOh0Op1O5w+/AMjcEKqVv0RIAAAAAElFTkSuQmCC';

export enum $currentUserData {
  tenantId = 'tenantId',
  workspaceId = 'workspaceId',
  digitalPropertyId = 'digitalPropertyId',
}

export interface ICurrentSelectedProperty {
  workspaceId: string;
  workspaceName: string;
  digitalPropertyId: string;
  digitalPropertyName: string;
  digitalPropertyKey: string;
}

export interface ICurrentSelectedWorkspace {
  workspaceId: string;
  workspaceName: string;
  workspaceDescription?: string;
  workspaceType?: WorkspaceTypes;
  workspaceStatus?: workspaceStatus;
  digitalProperties: IDigitalPropertyListItem[];
  workspaceSettings?: IWorkspaceSettings;
}

export interface ICurrentUserData {
  [$currentUserData.tenantId]?: string;
  [$currentUserData.workspaceId]?: string;
  [$currentUserData.digitalPropertyId]?: string;
}

@Injectable()
export class UserService implements OnDestroy {
  private subscription: Subscription;
  private userData: BehaviorSubject<IUserServerResponse>;
  private _isAuthenticated: BehaviorSubject<boolean>;
  private _isMfaRequired: BehaviorSubject<boolean | null>;
  private lastPermissionsCheck: Date;

  public userDataChanged$: Observable<IUserServerResponse>;
  public currentDigitalProperty$: Observable<IDigitalPropertyListItem | null>;
  public isAuthenticated$: Observable<boolean>;
  public isMfaRequired$: Observable<boolean | null>;
  public hasAccessToTenant$: BehaviorSubject<boolean>;
  public hasAWorkspaceSubject$: BehaviorSubject<boolean>;
  public currentTenant$: Observable<ITenantInfo>;

  constructor(
    private browserLocalStorageService: BrowserLocalStorageService,
    private userRestAPI: UserRestAPI,
    private adminRestAPI: AdminRestAPI,
    private authService: AuthService,
    private notificationService: NotificationService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private config: AppConfigService,
    private tenantService: TenantService,
    private appConfigService: AppConfigService,
  ) {
    this.subscription = new Subscription();

    this.userData = new BehaviorSubject(null);
    this.userDataChanged$ = this.userData.asObservable();

    this._isAuthenticated = new BehaviorSubject(false);
    this.isAuthenticated$ = this._isAuthenticated.asObservable();
    this._isMfaRequired = new BehaviorSubject(null);
    this.isMfaRequired$ = this._isMfaRequired.asObservable();
    this.hasAccessToTenant$ = new BehaviorSubject<boolean>(true);
    this.lastPermissionsCheck = new Date(0);
    this.hasAWorkspaceSubject$ = new BehaviorSubject<boolean>(true);

    this.currentDigitalProperty$ = this.userDataChanged$.pipe(
      filter((user: IUserServerResponse) => user !== null),
      map((user: IUserServerResponse): IDigitalPropertyListItem | null => {
        return user[$user.digitalProperties].find(this.isUserProperty(user[$user.lastUsedDigitalProperty]).bind(this)) || null;
      }),
    );

    this.currentTenant$ = this.userDataChanged$.pipe(
      filter((user: IUserServerResponse): boolean => SharedCommonUtility.notNullishOrEmpty(user?.[$user.currentTenantId])),
      switchMap(
        (user: IUserServerResponse): Observable<ITenantInfo> =>
          this.tenantService.getTenantFromTenantedScope(user[$user.currentTenantId]),
      ),
      AngularUtility.shareRef(),
    );
  }

  private isUserProperty(propertyId: string): (property: IDigitalPropertyListItem) => boolean {
    return (property: IDigitalPropertyListItem): boolean => {
      return property[$digitalProperty._id] === propertyId;
    };
  }

  private checkUserDigitalProperty(user: IUserServerResponse): void {
    const isActiveDigitalProperty = (workspace: IDigitalPropertyListItem): boolean => {
      return workspace._id === user[$user.lastUsedDigitalProperty];
    };

    const isPrivateWorkspace = (workspace: IWorkspaceClient): boolean => {
      return workspace.type === WorkspaceTypes.private;
    };

    const isPrivateDigitalProperty =
      (workspace: IWorkspaceClient) =>
      (dp: IDigitalPropertyListItem): boolean => {
        return dp[$digitalProperty.workspace]._id === workspace._id;
      };

    const activeWorkspaceIndex: number = user[$user.digitalProperties].findIndex(isActiveDigitalProperty);
    if (activeWorkspaceIndex >= 0) {
      return;
    }

    const privateWorkspace: IWorkspaceClient = user.workspaces.find(isPrivateWorkspace);
    if (privateWorkspace === undefined) {
      return;
    }

    const privateDigitalProperty: IDigitalPropertyListItem = user[$user.digitalProperties].find(
      isPrivateDigitalProperty(privateWorkspace),
    );

    if (privateDigitalProperty === undefined) {
      return;
    }

    // Note: TranslateService cannot be used here
    const appMessage: string =
      'You have been removed from this digital property. You are being switched to your private digital property';
    this.notificationService.error(appMessage, NotificationPosition.Toast, true, 'workspace_error', true);

    const switchedWorkspaceSuccess = (): void => {
      const propertyName = privateDigitalProperty[$digitalProperty.name];
      const message: string = `Switching to digital property ${propertyName} has been done successfully.`;
      this.notificationService.success(message, NotificationPosition.Toast);

      this.reloadUser();
    };

    const switchedWorkspaceFailed = (): void => {
      const message: string = 'There were errors while switching digital property';
      this.notificationService.error(message, NotificationPosition.Toast);
    };

    this.subscription.add(
      this.switchUserToDigitalProperty(
        privateDigitalProperty[$digitalProperty.workspace]._id,
        privateDigitalProperty._id,
      ).subscribe({
        next: switchedWorkspaceSuccess,
        error: switchedWorkspaceFailed,
      }),
    );
  }

  private saveProfileLocally(user: IUserServerResponse): IUserServerResponse | undefined {
    const me: IUserServerResponse = this.userData.getValue();

    // Note:  the user data may not only be for the logged in user, but also they are coming
    //        when user with role superAdmin updates the other users profile

    if (SharedCommonUtility.getTypeOf(me) === 'object' && user._id !== me._id) {
      return undefined;
    }

    const updatedUserData: IUserServerResponse = { ...me, ...user } as IUserServerResponse;

    this.checkUserDigitalProperty(updatedUserData);
    this.userData.next(updatedUserData);

    return user;
  }

  private handleUnauthorizedWhenGettingProfile(response: HttpErrorResponse): void {
    this.processUnauthorization();

    if (this.config.isUsingExternalIdp()) {
      this.router
        .navigate(['/', Api.forbidden])
        .then(() =>
          this.notificationService.error(this.errorMessageService.getGlobalErrorResponse(response), NotificationPosition.Toast),
        );
    } else {
      window.location.reload();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public userWorkspace(workspaceId: string): Observable<ICurrentSelectedWorkspace> {
    return this.userDataChanged$.pipe(
      filter((user: IUserServerResponse): boolean => user !== null),
      map((user: IUserServerResponse): ICurrentSelectedWorkspace => {
        const byId = (val: IWorkspaceClient): boolean => val._id === workspaceId;

        const userWorkspace: IWorkspaceClient = user[$user.workspaces].find(byId);

        if (typeof userWorkspace === 'undefined') {
          throw new Error(`Workspace "${workspaceId}" not found for the current user`);
        }

        const byWorkspace = (listItem: IDigitalPropertyListItem): boolean => {
          return listItem[$digitalProperty.workspace]._id === workspaceId;
        };

        return {
          workspaceId: userWorkspace._id,
          workspaceName: userWorkspace[$workspace.name],
          workspaceDescription: userWorkspace[$workspace.description],
          workspaceStatus: userWorkspace[$workspace.status],
          workspaceType: userWorkspace[$workspace.type] as WorkspaceTypes,
          digitalProperties: user[$user.digitalProperties].filter(byWorkspace),
        };
      }),
      take(1),
    );
  }

  public getDefaultAvatarURL(): string {
    return defaultAvatar;
  }

  /**
   * Returns the user avatar URL, or the default avatar if the user does not have an avatar set.
   *
   * @param user
   */
  public getAvatar(user: Pick<IUser, $user.avatar>): string {
    if (SharedCommonUtility.isNullish(user?.[$user.avatar])) {
      return this.getDefaultAvatarURL();
    }

    return user?.[$user.avatar];
  }

  /**
   * Retrieves the user avatar information from the backend. Uses the default avatar if the
   * user has not set an avatar.
   *
   * @param userId
   */
  public getAvatarSrc(userId: string): Observable<IImageSrcResponse> {
    return this.userRestAPI.getAvatarUrl(userId).pipe(
      map((res: IImageSrcResponse): IImageSrcResponse => {
        if (SharedCommonUtility.isNullish(res.src)) {
          return { ...res, src: this.getDefaultAvatarURL() };
        }

        return res;
      }),
    );
  }

  public processUnauthorization(): void {
    this._isAuthenticated.next(false);
    this._isMfaRequired.next(null);
    this.authService.clearLocalData();
  }

  public saveUserId(userId: string): void {
    this.browserLocalStorageService.setItem('userId', userId);
  }

  public getMeProfile(): Observable<IUserClient> {
    const success = (response: IUserClient): IUserClient => {
      this.saveProfileLocally(response);
      return response;
    };

    const error = (response: HttpErrorResponse): Observable<IUserClient> => {
      console.error('[getMeProfile] error', response);

      if (response.status === StatusCodes.UNAUTHORIZED) {
        this.handleUnauthorizedWhenGettingProfile(response);
      }
      if (
        response.status === StatusCodes.FORBIDDEN &&
        GlobalResponse.isIGlobalResponse(response.error) &&
        response.error.app.name === errorMessagesNames.NoAccessToTenant
      ) {
        this.hasAccessToTenant$.next(false);
      }

      return throwError(response);
    };

    if (this.isAuthenticated()) {
      return this.userRestAPI.getAuthenticatedUser().pipe(map(success), catchError(error));
    }

    return of(null);
  }

  public getAuthenticatedUser(): Observable<IUserServerResponse> {
    return this.userRestAPI.getAuthenticatedUser();
  }

  public reloadUser(): void {
    const reloadUserSubscription: Subscription = new Subscription();
    reloadUserSubscription.add(
      this.getMeProfile().subscribe({
        complete: () => reloadUserSubscription.unsubscribe(),
      }),
    );
  }

  public getAuthenticatedUserProfile(): Observable<IUserServerResponse> {
    return this.userData.asObservable();
  }

  public retrieveAuthenticatedUserProfile(): IUserServerResponse {
    return this.userData.getValue();
  }

  public getStoredUserId(): string {
    const userData: IUserServerResponse = this.userData.getValue();

    return userData ? userData._id : this.browserLocalStorageService.getItem('userId');
  }

  public updateUserDataByAdmin(profile: IUserProfileFormData, selectedUserId: string): Observable<IUser> {
    return this.userRestAPI.updateSelectedUserProfile(profile, selectedUserId);
  }

  public updateUserDataByWorkspaceAdmin(profile: FormData, workspaceId: string, selectedUserId: string): Observable<void> {
    return this.userRestAPI.updateSelectedUserProfileAsWorkspaceAdmin(profile, workspaceId, selectedUserId);
  }

  public updateUserAccountStatusByAdmin(accountStatus: string, selectedUserId: string): Observable<IUser> {
    return this.userRestAPI.updateSelectedUserProfile({ accountStatus } as IUserProfileFormData, selectedUserId);
  }

  public saveProfile(profile: FormData): Observable<IUserServerResponse> {
    return this.userRestAPI.saveUser(profile).pipe(map(this.saveProfileLocally.bind(this)));
  }

  public changePassword(passwordSettings: IUserPasswordSettings): Observable<void> {
    return this.userRestAPI.changePassword(passwordSettings);
  }

  public deactivateAccount(deactivateFeedback: IUserDeactivateAccount): Observable<IUserDeactivateAccount> {
    return this.userRestAPI.deactivateAccount(deactivateFeedback);
  }

  public verifyPreviousPassword(password: string): Observable<boolean> {
    return this.userRestAPI.verifyPreviousPassword(password);
  }

  public verifyPreviousPasswordVisitor(password: string, emailToken: string, skipLoader: boolean = false): Observable<boolean> {
    return this.userRestAPI.verifyPreviousPasswordVisitor(password, emailToken, skipLoader);
  }

  public setAuthenticated(status: boolean): void {
    this._isAuthenticated.next(status);
  }

  public setMfaRequired(status: boolean | null): void {
    this._isMfaRequired.next(status);
  }

  public isAuthenticated(): boolean {
    return this._isAuthenticated.getValue();
  }

  public isMfaRequired(): boolean | null {
    return this._isMfaRequired.getValue();
  }

  public verifyEmail(token: string): Observable<IUserServerResponse> {
    return this.userRestAPI.verifyEmail(token);
  }

  public verifyResetPasswordToken(token: string): Observable<{ user: { id: string } }> {
    return this.userRestAPI.verifyResetPasswordToken(token);
  }

  public async getAuthStatus(): Promise<Observable<HttpResponse<IGlobalResponse>> | Observable<HttpErrorResponse | undefined>> {
    const success = (response: HttpResponse<IGlobalResponse>): Observable<HttpResponse<IGlobalResponse>> => {
      const isUserAuthenticated: boolean = response.body.app.user.authenticated;
      this.setAuthenticated(isUserAuthenticated);

      if (isUserAuthenticated) {
        this.setMfaRequired(false);
      }

      return of(response);
    };

    const error = (response: HttpErrorResponse): Observable<HttpErrorResponse | undefined> => {
      console.error('[getAuthStatus] error', response);

      if (response.status === StatusCodes.FORBIDDEN) {
        throw response as Error;
      }

      this.setAuthenticated(false);

      return throwError(response);
    };

    if (!this.appConfigService.useCookiesForAuth()) {
      this.subscription.add(
        this.authService
          .getAccessTokenObservable()
          .pipe(
            withLatestFrom(this.isMfaRequired$),
            map(
              ([token, isMfaRequired]: [string, boolean | null]) =>
                SharedCommonUtility.notNullish(token) && SharedCommonUtility.notNullish(isMfaRequired),
            ),
          )
          .subscribe((isUserAuthenticated: boolean) => {
            this.setAuthenticated(isUserAuthenticated);
          }),
      );

      const accessToken: string | null = this.authService.getAccessTokenFromStorage();

      if (accessToken === null) {
        this.setAuthenticated(false);
        return Promise.resolve(null);
      }
    }

    return this.userRestAPI.authStatus().toPromise().then(success).catch(error);
  }

  public validateExternalAccessToken(): Observable<boolean> {
    return this.userRestAPI.authStatus().pipe(
      map((response: HttpResponse<IGlobalResponse>): boolean => {
        return response?.body?.app?.user?.authenticated ?? false;
      }),
      catchError((response: HttpErrorResponse): Observable<boolean> => {
        if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.ExternalAuthUserNotFound) {
          return of(false);
        }
        return throwError(response);
      }),
    );
  }

  public logIn(email: string, password: string): Observable<void | unknown> {
    const success = (response: HttpResponse<{ userId: string; mfaRequired: boolean }>): void => {
      this.authService.saveAccessTokenToStorage(response.headers.get('x-access-token'));
      this.authService.saveRefreshTokenInStorage(response.headers.get('x-refresh-token'));

      this.saveUserId(response.body.userId);
      this.setMfaRequired(response.body.mfaRequired);
      this.setAuthenticated(!response.body.mfaRequired);
    };

    const error = (response: HttpErrorResponse): Observable<EmptyObject> => {
      console.error('[UserService] logIn error', response);

      this.setAuthenticated(false);

      return throwError(response);
    };

    const data = {
      email: email,
      password: password,
    };

    return this.userRestAPI.login(data).pipe(map(success), catchError(error));
  }

  public postLogOut(): void {
    this.authService.clearLocalData();
    this.setAuthenticated(false);
    this.setMfaRequired(null);
    this.userData.next(null);
  }

  public logOut(): Observable<void> {
    const success = (): void => {
      this.postLogOut();
    };

    const error = (response: HttpErrorResponse): Observable<void> => {
      this.postLogOut();

      return throwError(response);
    };

    return this.userRestAPI.logout().pipe(map(success), catchError(error));
  }

  public signup(data: IUserSignUpRequest): Observable<IUserSignupResponse> {
    return this.userRestAPI.signup(data).pipe(this.signupResponseHandler.bind(this));
  }

  private signupResponseHandler(observable: Observable<IUserSignupResponse>): Observable<IUserSignupResponse> {
    const success = (response: IUserSignupResponse): void => {
      console.debug('[UserService] signUp success', response);

      this.authService.saveAccessTokenToStorage(response.accessToken);
      this.authService.saveRefreshTokenInStorage(response.refreshToken);

      this.saveUserId(response.userId);
    };

    const error = (response: HttpErrorResponse): Observable<never> => {
      console.error('[UserService] signUp error', response);

      this.setAuthenticated(false);
      return throwError(response);
    };

    return observable.pipe(tap(success), catchError(error));
  }

  public getMfaRegistrationQr(): Observable<IMfaRegistrationQrResponse> {
    return this.userRestAPI.getMfaRegistrationQr();
  }

  public verifyAndEnableMfa(request: IMfaTokenVerificationRequest): Observable<void> {
    return this.userRestAPI.verifyAndEnableMfa(request);
  }

  public mfaLogin(request: IMfaLoginRequest): Observable<void> {
    const success = (response: HttpResponse<{ userId: string }>): void => {
      this.authService.saveAccessTokenToStorage(response.headers.get('x-access-token'));
      this.authService.saveRefreshTokenInStorage(response.headers.get('x-refresh-token'));

      this.saveUserId(response.body.userId);
      this.setAuthenticated(true);
      this.setMfaRequired(false);
    };

    const error = (response: HttpErrorResponse): Observable<void> => {
      console.error('[UserService] mfaLogin error', response);

      this.setAuthenticated(false);

      return throwError(response);
    };

    return this.userRestAPI.mfaLogin(request).pipe(map(success), catchError(error));
  }

  public disableMfa(): Observable<void> {
    return this.userRestAPI.disableMfa();
  }

  public disableMfaForUser(userId: string): Observable<void> {
    return this.userRestAPI.disableMfaForUser(userId);
  }

  public getMfaStatus(): Observable<IMfaStatusResponse> {
    return this.userRestAPI.getMfaStatus();
  }

  public getMfaStatusForUser(userId: string): Observable<IMfaStatusResponse> {
    return this.userRestAPI.getMfaStatusForUser(userId);
  }

  public resetPassword(email: string): Observable<EmptyObject> {
    return this.userRestAPI.resetPassword(email);
  }

  public resendActivationLink(email: string): Observable<EmptyObject> {
    return this.userRestAPI.resendActivationLink(email);
  }

  public resendVisitorActivationLink(email: string): Observable<EmptyObject> {
    return this.userRestAPI.resendVisitorActivationLink(email);
  }

  public changePasswordWithToken(password: string, verificationToken: string): Observable<EmptyObject> {
    return this.userRestAPI.createNewPassword(password, verificationToken);
  }

  public cleanAfterDeactivateAccount(): void {
    this.authService.clearLocalData();
    this.setAuthenticated(false);
  }

  public getUserById(userId: string): Observable<IUserServerResponse | IUserPublicResponse> {
    return this.userRestAPI.getUserById(userId);
  }

  public getUsers(queryOptions: IHttpQueryOptions): Observable<IGetUsersResponseData> {
    return this.userRestAPI.getUsers(queryOptions);
  }

  public getRecentWorkspaces(toolName: AccessibilityAuditToolNames): Observable<IRecentUserWorkspace[]> {
    return this.userRestAPI.getRecentWorkspaces(toolName);
  }

  public changeEmailsDownloadTemplate(): Observable<void> {
    return this.userRestAPI.changeEmailsDownloadTemplate();
  }

  public updateEmails(fileForm: FormData): Observable<IBulkEmailUpdateResponse> {
    return this.userRestAPI.updateEmails(fileForm);
  }

  public onBrowserOnlineStatus(): Observable<boolean> {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    );
  }

  public getApplicationRoles(): Observable<IWorkspaceRolesResponse> {
    return this.adminRestAPI.getApplicationRoles();
  }

  public switchUserToWorkspace(workspaceId: string): Observable<IUserServerResponse> {
    const success = (userServerResponse: IUserServerResponse): IUserServerResponse => {
      this.saveProfileLocally(userServerResponse);
      return userServerResponse;
    };

    return this.userRestAPI.switchUserToWorkspace(workspaceId).pipe(map(success));
  }

  public switchUserToDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IUserServerResponse> {
    const success = (userServerResponse: IUserServerResponse): IUserServerResponse => {
      this.saveProfileLocally(userServerResponse);
      return userServerResponse;
    };

    return this.userRestAPI.switchUserToDigitalProperty(workspaceId, digitalPropertyId).pipe(map(success));
  }

  public getUserPreferences(
    scope: userPreferenceScope,
    workspaceId?: string,
    projectId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    return this.userRestAPI.getUserPreferences(scope, workspaceId, projectId);
  }

  public getUserPreferencesForUser(
    scope: userPreferenceScope,
    userId: string,
    workspaceId?: string,
    projectId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    return this.userRestAPI.getUserPreferencesForUser(scope, userId, workspaceId, projectId);
  }

  public updateUserPreferences(
    scope: userPreferenceScope,
    preferences: { [key: string]: any },
    workspaceId?: string,
    projectId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    const preferencesPayload: { [key: string]: any } = Object.keys(preferences).map(
      (
        param: string,
      ): {
        [key: string]: any;
      } => ({
        scope,
        param,
        value: preferences[param],
        projectId,
      }),
    );

    return this.userRestAPI.updateUserPreferences(preferencesPayload, scope, workspaceId);
  }

  public updateUserPreferencesForUser(
    scope: userPreferenceScope,
    preferences: { [key: string]: any },
    userId: string,
    workspaceId?: string,
    projectId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    const preferencesPayload: { [key: string]: any } = Object.keys(preferences).map(
      (
        param: string,
      ): {
        [key: string]: any;
      } => ({
        scope,
        param,
        value: preferences[param],
        projectId,
      }),
    );

    return this.userRestAPI.updateUserPreferencesForUser(preferencesPayload, scope, userId, workspaceId);
  }

  public checkUserPermissionsChange(): void {
    // Note: we add a this 'debouncing' to avoid looping on getting the profile
    const now: Date = new Date();
    if (now.getTime() - this.lastPermissionsCheck.getTime() < 60 * 1000) {
      return;
    }

    this.lastPermissionsCheck = now;
    this.reloadUser();
  }

  public hasTenantStaffAccess(): Observable<boolean> {
    return this.userDataChanged$.pipe(
      filter((profile: IUserServerResponse): boolean => SharedCommonUtility.notNullish(profile)),
      take(1),
      map((profile: IUserServerResponse): boolean =>
        profile[$user.groups].some((group: ISecurityGroup): boolean => group[$securityGroup.isStaffGroup] === true),
      ),
    );
  }

  public isAdmin(): Observable<boolean> {
    const adminRoles: string[] = [AclRoles.admin, AclRoles.superAdmin];
    const hasAdminRole = (role: IApplicationRole): boolean => adminRoles.includes(role[$applicationRole.name]);

    return this.userDataChanged$.pipe(
      filter((profile: any) => profile !== null),
      take(1),
      map((profile: IUserServerResponse): boolean => profile[$user.applicationRoles].some(hasAdminRole)),
    );
  }

  public isSuperAdmin(): Observable<boolean> {
    const adminRoles: string[] = [AclRoles.superAdmin];
    const hasAdminRole = (role: IApplicationRole): boolean => adminRoles.includes(role[$applicationRole.name]);

    return this.userDataChanged$.pipe(
      filter((profile: any) => profile !== null),
      take(1),
      map((profile: IUserServerResponse): boolean => profile[$user.applicationRoles].some(hasAdminRole)),
    );
  }

  public getUserDigitalPropertyIds(): Observable<IWorkspaceIDItem[]> {
    return this.userRestAPI.getAvailableDigitalPropertyIds();
  }

  public checkAccessToProperty(digitalPropertyId: string, workspaceId: string): Observable<boolean> {
    return this.userRestAPI.checkAccessToProperty(digitalPropertyId, workspaceId).pipe(map(() => true));
  }

  public setToNoWorkspaceState(): void {
    if (this.hasAWorkspaceSubject$.getValue()) {
      this.hasAWorkspaceSubject$.next(false);
    }
    this.router.navigate(['/', Api.forbidden]).then();
  }

  public getUserDataForLogging(): ICurrentUserData {
    const userData: IUserServerResponse = this.userData.getValue();
    const digitalProperty: IDigitalPropertyListItem = userData?.[$user.digitalProperties]?.find(
      (property: IDigitalPropertyListItem): boolean => property[$digitalProperty._id] === userData[$user.lastUsedDigitalProperty],
    );

    return {
      [$currentUserData.tenantId]: userData?.[$user.currentTenantId],
      [$currentUserData.workspaceId]: digitalProperty?.[$digitalProperty.workspace][$workspace._id],
      [$currentUserData.digitalPropertyId]: digitalProperty?.[$digitalProperty._id],
    };
  }

  public getEmailByToken(token: string): Observable<IUserEmailResponse> {
    return this.userRestAPI.getEmailByToken(token);
  }

  public static findUserPreferenceForParam(
    response: IUserPreferencesServerResponse,
    param: userPreferenceScopeParam,
    scope: userPreferenceScope,
  ): IUserPreference | null {
    return (
      response.userPreferences.find((userPreference: IUserPreference) => {
        const matchingParam: boolean = userPreference[$userPreference.param] === param;
        const matchingScope: boolean = userPreference[$userPreference.scope] === scope;
        return matchingParam && matchingScope;
      }) ?? null
    );
  }
}
