import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IIssueIntegrationConnection,
  IIssueTrackingIssueStatus,
  IIssueTrackingIssueType,
  IJiraUser,
  IPopulatedIssueIntegrationConnection,
  IRequiredField,
  IStatusMapping,
} from '../../../shared/interfaces/issue-integration-connection.interface';
import {
  IJiraIntegrationConsumerKeyResponse,
  IJiraIntegrationResponse,
} from '../../../shared/interfaces/issue-tracking.interface';
import { IssueTrackingRestAPI } from './rest/issue-tracking.api';
import {
  IConfigureIssueTrackingFields,
  ICreateIssueTrackingIssueRequest,
  IIssueTrackingConnectionCreateRequest,
  IIssueTrackingConnectionReauthorizeRequest,
  IIssueTrackingConnectionRegenerateAuthUrlRequest,
  IIssueTrackingConnectionUpdateWorkspacesRequest,
  ILinkIssueTrackingIssueRequest,
  IssueTrackingFieldsConfig,
} from '../../../shared/interfaces/issue-tracking.requests.interface';
import { IIssueTrackingConnectionRegenerateAuthUrlResponse } from '../../../shared/interfaces/issue-tracking.responses.interface';
import { IIssueTrackingProject } from '../../../shared/interfaces/project.interface';
import { $issueIntegrationType, $taskTransitionType } from '../../../shared/constants/issue-tracking';
import { IIssueIntegrationStatusMapping } from '../../../shared/interfaces/issue-integration-status-mapping.interface';
import { IFailedTask } from '../../../shared/interfaces/task.interface';
import { $issueIntegrationConnection } from '../../../shared/constants/issue-tracking-connection';

@Injectable({
  providedIn: 'root',
})
export class IssueTrackingService {
  constructor(private issueTrackingRestAPI: IssueTrackingRestAPI) {}

  public getTenantIssueTrackingConnectionConfig(): Observable<IJiraIntegrationResponse> {
    return this.issueTrackingRestAPI.getTenantIssueTrackingConnectionConfig();
  }

  public getTenantIssueTrackingConnectionConsumerKey(connectionId: string): Observable<IJiraIntegrationConsumerKeyResponse> {
    return this.issueTrackingRestAPI.getTenantIssueTrackingConnectionConsumerKey(connectionId);
  }

  public createIssueTrackingConnection(
    issueTrackingConnectionCreateRequest: IIssueTrackingConnectionCreateRequest,
  ): Observable<IIssueIntegrationConnection> {
    return this.issueTrackingRestAPI.createIssueTrackingConnection(issueTrackingConnectionCreateRequest);
  }

  public updateIssueTrackingConnectionWorkspaces(
    issueTrackingConnectionId: string,
    issueTrackingConnectionUpdateWorkspacesRequest: IIssueTrackingConnectionUpdateWorkspacesRequest,
  ): Observable<void> {
    return this.issueTrackingRestAPI.updateIssueTrackingConnectionWorkspaces(
      issueTrackingConnectionId,
      issueTrackingConnectionUpdateWorkspacesRequest,
    );
  }

  public regenerateIssueTrackingAuthorizeUrl(
    connectionId: string,
    issueTrackingConnectionRegenerateAuthUrlRequest: IIssueTrackingConnectionRegenerateAuthUrlRequest,
  ): Observable<IIssueTrackingConnectionRegenerateAuthUrlResponse> {
    return this.issueTrackingRestAPI.regenerateIssueTrackingAuthorizeUrl(
      connectionId,
      issueTrackingConnectionRegenerateAuthUrlRequest,
    );
  }

  public getUserIssueTrackingConnections(): Observable<IPopulatedIssueIntegrationConnection[]> {
    return this.issueTrackingRestAPI.getUserIssueTrackingConnections();
  }

  public getIssueTrackingConnectionsForWorkspace(workspaceId: string): Observable<IPopulatedIssueIntegrationConnection[]> {
    return this.issueTrackingRestAPI.getIssueTrackingConnectionsForWorkspace(workspaceId);
  }

  public getIssueTrackingProjects(issueTrackingConnectionId: string): Observable<IIssueTrackingProject[]> {
    return this.issueTrackingRestAPI.getIssueTrackingProjects(issueTrackingConnectionId);
  }

  public getIssueTrackingIssueTypes(connectionId: string, issueTrackingProjectId: string): Observable<IIssueTrackingIssueType[]> {
    return this.issueTrackingRestAPI.getIssueTrackingIssueTypes(connectionId, issueTrackingProjectId);
  }

  public getConfigurableFields(
    connectionId: string,
    issueTrackingProjectId: string,
    issueTrackingIssueTypeId: string,
    allFields: boolean,
  ): Observable<IRequiredField[]> {
    if (allFields) {
      return this.issueTrackingRestAPI.getConfigurableFields(connectionId, issueTrackingProjectId, issueTrackingIssueTypeId);
    }
    return this.issueTrackingRestAPI.getRequiredFields(connectionId, issueTrackingProjectId, issueTrackingIssueTypeId);
  }

  public getConfiguredFieldsForProject(
    workspaceId: string,
    projectId: string,
    issueTrackingIssueTypeId: string,
  ): Observable<IssueTrackingFieldsConfig> {
    return this.issueTrackingRestAPI.getConfiguredFieldsForProject(workspaceId, projectId, issueTrackingIssueTypeId);
  }

  public getJiraUsersByProject(connectionId: string, issueIntegrationProjectKey: string): Observable<IJiraUser[]> {
    return this.issueTrackingRestAPI.getJiraUsersByProject(connectionId, issueIntegrationProjectKey);
  }

  public updateEapProjectStatusMapping(
    workspaceId: string,
    projectId: string,
    statusMappingFromIssueTracking: IStatusMapping[],
    statusMappingToIssueTracking: IStatusMapping[] = [],
    configurationType: $taskTransitionType,
  ): Observable<IIssueIntegrationStatusMapping> {
    return this.issueTrackingRestAPI.updateEapProjectStatusMapping(
      workspaceId,
      projectId,
      statusMappingFromIssueTracking,
      statusMappingToIssueTracking,
      configurationType,
    );
  }

  public getIssueTrackingIssueStatusesByProject(
    issueIntegrationProjectId: string,
    connectionId: string,
  ): Observable<IIssueTrackingIssueStatus[]> {
    return this.issueTrackingRestAPI.getIssueTrackingIssueStatusesByProject(issueIntegrationProjectId, connectionId);
  }

  public getIssueTrackingIssueStatusesByIssueType(
    connectionId: string,
    projectId: string,
    issueTypeId: string,
  ): Observable<IIssueTrackingIssueStatus[]> {
    return this.issueTrackingRestAPI.getIssueTrackingIssueStatusesByIssueType(connectionId, projectId, issueTypeId);
  }

  public createIssue(
    connectionId: string,
    projectId: string,
    createJiraIssueRequest: ICreateIssueTrackingIssueRequest[],
  ): Observable<void> {
    return this.issueTrackingRestAPI.createJiraIssue(connectionId, projectId, createJiraIssueRequest);
  }

  public linkIssue(
    connectionId: string,
    projectId: string,
    linkIssueRequest: ILinkIssueTrackingIssueRequest[],
  ): Observable<void> {
    return this.issueTrackingRestAPI.linkIssue(connectionId, projectId, linkIssueRequest);
  }

  public getIssueTrackingConnectionInfo(issueTrackingConnectionId: string): Observable<IPopulatedIssueIntegrationConnection> {
    return this.issueTrackingRestAPI.getIssueTrackingConnectionInfo(issueTrackingConnectionId);
  }

  public verifyTenantJiraToken(issueTrackingConnectionId: string, verificationCode: string): Observable<void> {
    return this.issueTrackingRestAPI.verifyTenantJiraToken(issueTrackingConnectionId, { verificationCode });
  }

  public removeIssueTrackingConnection(issueTrackingConnectionId: string): Observable<void> {
    return this.issueTrackingRestAPI.removeTenantIssueTrackingConnection(issueTrackingConnectionId);
  }

  public resyncIssueTrackingConnection(issueTrackingConnectionId: string): Observable<void> {
    return this.issueTrackingRestAPI.resyncIssueTrackingConnection(issueTrackingConnectionId);
  }

  public configureFieldsForProject(
    workspaceId: string,
    projectId: string,
    newFields: IConfigureIssueTrackingFields,
  ): Observable<void> {
    return this.issueTrackingRestAPI.configureFieldsForProject(workspaceId, projectId, newFields);
  }

  public reauthorizeIssueTrackingConnection(
    issueTrackingConnectionId: string,
    issueTrackingConnectionReauthorizeRequest: IIssueTrackingConnectionReauthorizeRequest,
  ): Observable<void> {
    return this.issueTrackingRestAPI.reauthorizeIssueTrackingConnection(
      issueTrackingConnectionId,
      issueTrackingConnectionReauthorizeRequest,
    );
  }

  public getLabels(issueTrackingConnectionId: string, query: string, fieldName: string): Observable<string[]> {
    return this.issueTrackingRestAPI.getLabels(issueTrackingConnectionId, query, fieldName);
  }

  public getFailedTasks(issueTrackingConnectionId: string): Observable<IFailedTask[]> {
    return this.issueTrackingRestAPI.getFailedTasks(issueTrackingConnectionId);
  }

  public isJiraCloudInstance(instance: string): boolean {
    try {
      const url: URL = new URL(instance);
      const atlassianRegExp: RegExp = /\.atlassian\.net$/i;
      return atlassianRegExp.test(url.hostname);
    } catch (error) {
      return false;
    }
  }

  public shouldReauthorizeConnection(connection: IIssueIntegrationConnection): boolean {
    return (
      connection?.[$issueIntegrationConnection.integrationType] === $issueIntegrationType.jira &&
      (!connection[$issueIntegrationConnection.oAuth2] || !connection[$issueIntegrationConnection.webhooksSupported]) &&
      this.isJiraCloudInstance(connection[$issueIntegrationConnection.instance])
    );
  }
}
