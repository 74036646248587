import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  DsButtonColor,
  DsButtonVariants,
  DsModalColorVariant,
  DsModalRef,
  DsModalService,
  DsModalVariant,
  Icons,
} from '@levelaccess/design-system';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';

import { UserNotificationService } from '../../../../services/user-notification.service';
import { INavMenuItem, NavigationItem } from '../../../../services/navigation/nav-structure';
import { NavService } from '../../../../services/navigation/nav.service';
import { MobileSecondNavbarMenuModalComponent } from '../../compound-second-level-navbar/mobile-second-navbar-menu-modal/mobile-second-navbar-menu-modal.component';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { FeatureFlagService } from '../../../../services/feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../../../../shared/interfaces/feature-flag.interface';

@Component({
  selector: 'app-mobile-top-navbar-menu-modal',
  templateUrl: './mobile-top-navbar-menu-modal.component.html',
  styleUrls: ['./mobile-top-navbar-menu-modal.component.scss'],
})
export class MobileTopNavbarMenuModalComponent implements OnInit {
  public DsModalColorVariant: typeof DsModalColorVariant;
  public DsButtonVariants: typeof DsButtonVariants;
  public Icons: typeof Icons;
  public DsButtonColor: typeof DsButtonColor;
  public NavigationItem: typeof NavigationItem;

  public topLevelMenuItems$: Observable<INavMenuItem[]>;
  public notificationNumber$: Observable<number>;

  @Input() public modal: NgbActiveModal;

  constructor(
    private dsModalService: DsModalService,
    private userNotificationService: UserNotificationService,
    private navService: NavService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.DsModalColorVariant = DsModalColorVariant;
    this.Icons = Icons;
    this.DsButtonVariants = DsButtonVariants;
    this.DsButtonColor = DsButtonColor;
    this.NavigationItem = NavigationItem;
  }

  private getTopLevelNavItems(): void {
    /**
     * The getTopLevelMenuItems$ observable does not guarantee order.  Any new top level navigation items
     * added to the menu modal must be added to this variable in the expected location so the navigation
     * items can be sorted properly.
     */
    const navItems: NavigationItem[] = [
      NavigationItem.organization_dashboard,
      NavigationItem.workspace,
      NavigationItem.evaluations,
      NavigationItem.organization_settings,
      NavigationItem.tools_and_integrations,
      NavigationItem.administration,
      NavigationItem.internal_use_only,
      NavigationItem.academy,
      NavigationItem.old_help,
      NavigationItem.help,
      NavigationItem.service_request,
      NavigationItem.notifications,
      NavigationItem.profile,
      NavigationItem.logout,
    ];

    const sortByNavigationOrder = (a: INavMenuItem, b: INavMenuItem): number => {
      if (a.id === b.id) {
        return 0;
      }
      return navItems.indexOf(a.id) < navItems.indexOf(b.id) ? -1 : 1;
    };

    this.topLevelMenuItems$ = this.navService.getTopLevelMenuItems$(navItems).pipe(
      withLatestFrom(this.featureFlagService.variation$(FeatureFlagCollection.navSupportPortalUx, false)),
      map(([items, navSupportPortalUxEnabled]: [INavMenuItem[], VariationValue]): INavMenuItem[] =>
        items
          ?.filter((item: INavMenuItem): boolean => item?.isAvailable)
          .filter(
            (item: INavMenuItem): boolean => item.id !== NavigationItem.service_request || Boolean(navSupportPortalUxEnabled),
          )
          .filter((item: INavMenuItem): boolean => {
            if (navSupportPortalUxEnabled) {
              return item.id !== NavigationItem.old_help;
            }
            return item.id !== NavigationItem.help;
          })
          .sort(sortByNavigationOrder),
      ),
    );
  }

  public hasAvailableChildren(menuItem: INavMenuItem): boolean {
    return menuItem.children?.some((childMenu: INavMenuItem): boolean => childMenu.isAvailable);
  }

  public openSecondLevelModal(menuItem: INavMenuItem): void {
    const modalRef: DsModalRef | null = this.dsModalService.open(MobileSecondNavbarMenuModalComponent, DsModalVariant.fullScreen);

    if (SharedCommonUtility.notNullish(modalRef)) {
      const navModal: MobileSecondNavbarMenuModalComponent = modalRef.componentInstance;
      navModal.parentMenuItem = menuItem;
    }
  }

  public onModalClose(): void {
    this.dsModalService.closeAllModals();
  }

  public ngOnInit(): void {
    this.notificationNumber$ = this.userNotificationService.newNotificationsCount$;
    this.getTopLevelNavItems();
  }
}
