import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AclSecurityAdapter, AclService } from './acl.service';
import { $currentUserData, UserService } from './user.service';
import { AclPermissions } from '../../../shared/constants/acl';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';

@Injectable()
export class UserAclService {
  constructor(
    private aclService: AclService,
    private userService: UserService,
  ) {}

  public canAccessResource(requiredPermissions: readonly AclPermissions[], targetWorkspace?: string): Observable<boolean> {
    return this.userService.userDataChanged$.pipe(
      filter((profile: IUserServerResponse) => profile !== null),
      take(1),
      map((profile: IUserServerResponse): boolean =>
        this.aclService.canUserAccessResource(profile, requiredPermissions, targetWorkspace),
      ),
    );
  }

  public createCheckAccessForCurrentUser(): Observable<AclSecurityAdapter> {
    return this.userService.userDataChanged$.pipe(
      filter((profile: IUserServerResponse): boolean => profile !== null),
      map(
        (user: IUserServerResponse): AclSecurityAdapter =>
          new AclSecurityAdapter(user, this.aclService, user[$currentUserData.tenantId]),
      ),
    );
  }
}
