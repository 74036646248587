<ds-modal [modal]="modal" [title]="'menu' | translate" [colorVariant]="DsModalColorVariant.DarkBlue" [isInlineTitle]="true">
  <ul>
    <ng-container *ngFor="let menuItem of topLevelMenuItems$ | async">
      <li
        *ngIf="menuItem.isAvailable"
        [class.separator-bottom]="menuItem.id === NavigationItem.workspace"
        [class.separator-top]="menuItem.id === NavigationItem.help || menuItem.id === NavigationItem.old_help"
      >
        <button
          ds-button
          *ngIf="hasAvailableChildren(menuItem); else menuItemLink"
          [variant]="DsButtonVariants.microActionWithChevronRight"
          [color]="DsButtonColor.White"
          [microActionIcon]="menuItem.iconName"
          
          (click)="openSecondLevelModal(menuItem)"
        >
          {{ menuItem.label | translate }}
        </button>
        <ng-template #menuItemLink>
          <a
            ds-button
            [variant]="DsButtonVariants.microActionHorizontalLayout"
            [color]="DsButtonColor.White"
            [microActionIcon]="menuItem.iconName"
            [routerLink]="menuItem.routerLink"
            
            (click)="onModalClose()"
          >
            {{ menuItem.label | translate }}
            <ng-container *ngIf="menuItem.id === NavigationItem.notifications">
              <span class="ds-visually-hidden">&nbsp;</span>
              <span class="notifications-badge" >{{ notificationNumber$ | async }}</span>
              <span class="ds-visually-hidden">&nbsp;{{ 'unread' | translate }}</span>
            </ng-container>
          </a>
        </ng-template>
      </li>
    </ng-container>
  </ul>
</ds-modal>
