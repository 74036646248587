<footer class="app-footer">
  <nav class="container-fluid" [attr.aria-label]="'footer_menu' | translate" [class.text-light]="useTextLight$ | async">
    <span class="footer-text">
      © {{ footerTitle }},
      <span title="{{ 'application_publish_date' | translate: appVersion.publishDate }}">
        {{ 'application_version' | translate: appVersion.version }}
      </span>
    </span>
    <span aria-hidden="true">|</span>
    <a href="https://www.levelaccess.com/terms-service" target="_blank">{{ 'terms_of_use' | translate }}</a>
    <span aria-hidden="true">|</span>
    <a href="https://www.levelaccess.com/privacy-policy" target="_blank">{{ 'privacy_policy' | translate }}</a>
    <span aria-hidden="true">|</span>
    <a [routerLink]="['/', Api.cookie_policy]" [target]="linkTarget" [rel]="linkRel">{{ 'cookie_policy' | translate }}</a>
    <ng-container *ngIf="showAttributionLink$ | async">
      <span aria-hidden="true">|</span>
      <a [routerLink]="['/', Api.attribution]" [target]="linkTarget" [rel]="linkRel">{{ 'attribution_label' | translate }}</a>
    </ng-container>
  </nav>

  <ng-container *ngIf="cookieMessageClosed === false">
    <div class="alert alert-info cookies-message">
      <span class="visuallyhidden">{{ 'cookies_disclaimer' | translate }}</span>
      <span [innerHTML]="'cookie_usage_message' | translateHtml: '/cookie-policy'"></span>
      <button class="margin-left btn-primary btn-xs" (click)="removeCookiesUsageMessage()">
        {{ 'accept' | translate }}
      </button>
    </div>
  </ng-container>
</footer>
