import { Component } from '@angular/core';

@Component({
  selector: 'app-login-card',
  template: `
    <div class="d-flex">
      <div class="align-middle card-wrapper">
        <div class="card">
          <div class="card-header d-flex justify-content-center align-items-center">
            <img
              class="card-la-logo"
              src="/static/assets/images/LevelAccess_Logo_Midnight-RGB.svg"
              alt="{{ 'level_access' | translate }}"
            />
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <ng-content />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrl: './login-card.component.scss',
})
export class LoginCardComponent {}
