<app-login-card>
  <form name="form" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row form-row">
      <div class="col-md-12">
        <h1 class="text-center">{{ 'login' | translate }}</h1>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-12">
        <ds-input
          [label]="formFieldsLabel[FormField.organizationUrl] | translate"
          [errorMessage]="formFieldErrors[FormField.organizationUrl]"
          [errorState]="formFieldErrors[FormField.organizationUrl]?.length > 0"
          [required]="true"
        >
          <input
            ds-input-field
            type="text"
            [formControlName]="FormField.organizationUrl"
            
          />
        </ds-input>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col-md-12 text-end">
        <button
          type="submit"
          class="btn-login btn-lg w-100 justify-content-center"
          [disabled]="form.disabled"
          
        >
          {{ 'label_continue' | translate }}
        </button>
      </div>
    </div>
  </form>
</app-login-card>
