<app-login-card *ngIf="isUserAuthenticated === false && isMfaRequired !== false">
  <ng-container *ngIf="isMfaRequired === null">
    <div *ngIf="passwordIsTooOldError" class="row form-row">
      <div class="col-12">
        <app-alert
          [type]="alertType.danger"
          [header]="'you_need_to_reset_your_password' | translate"
          [message]="'password_expiration_message' | translate"
        ></app-alert>
      </div>
    </div>
    <div *ngIf="userEmailUnverifiedError" class="row form-row">
      <div class="col-12">
        <app-edit-permissions-banner [bannerType]="BannerType.error" (onClose)="onUserUnverifiedEmailBannerClose()">
          {{ 'your_email_is_not_verified' | translate }} <br />
          {{ 'verify_email_to_login' | translate }}
          <button
            ds-button
            [variant]="DsButtonVariants.microAction"
            [microActionIcon]="Icons.Plus"
            [hideMicroActionIcon]="true"
            (click)="onResendActivationLink()"
          >
            {{ 'resend_verification' | translate }}
          </button>
        </app-edit-permissions-banner>
      </div>
    </div>
    <div *ngIf="authErrorMessage.length > 0" class="row form-row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <app-alert
          [type]="alertType.danger"
          [header]="'authentication_error' | translate"
          [message]="authErrorMessage"
        ></app-alert>
        <app-form-error-messages
          [header]="'form_validation_error_title' | translate"
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
        ></app-form-error-messages>
      </div>
    </div>

    <form name="form" (ngSubmit)="onSubmit()" [formGroup]="form">
      <fieldset>
        <legend class="d-none visuallyhidden" [attr.aria-hidden]="true">
          {{ 'log_in' | translate }}
        </legend>

        <div class="row form-row">
          <div class="col-md-12">
            <h1 id="login">{{ 'login' | translate }}</h1>
          </div>
        </div>

        <div class="row form-row">
          <div class="col-md-12 align-items-center d-flex margin-bottom-xs">
            <label id="email-label" aria-required="true">{{ 'form_label_email' | translate }}</label>
          </div>
          <div class="col-md-12">
            <input
              autocomplete="email"
              type="email"
              class="form-element w-100"
              id="email"
              formControlName="email"
              required="required"
              placeholder="{{ 'enter_email_here' | translate }}"
              [attr.aria-invalid]="!isFieldValid('email')"
              aria-labelledby="email-label invalid-email"
            />
          </div>
        </div>

        <div class="row form-row">
          <div class="col-md-12" id="invalid-email">
            <app-form-field-error
              [formValidationRequest$]="formValidationRequest$"
              [field]="form.controls['email']"
              [name]="'email'"
              [label]="'form_label_email'"
            ></app-form-field-error>
          </div>
        </div>

        <div class="row form-row">
          <div class="col-md-12 align-items-center d-flex margin-bottom-sm">
            <label id="password-label" aria-required="true">{{ 'password' | translate }}</label>
          </div>

          <div class="col-md-12 input-group">
            <input
              [type]="isPasswordShown ? 'text' : 'password'"
              id="password"
              formControlName="password"
              required="required"
              [attr.aria-invalid]="!isFieldValid('password')"
              autocomplete="new-password"
              class="form-element w-100"
              [ngClass]="{ 'password-field': usePasswordRevealButton }"
              placeholder="{{ 'enter_password_here' | translate }}"
              aria-labelledby="password-label invalid-password"
            />

            <ng-container *ngIf="usePasswordRevealButton">
              <button
                type="button"
                aria-describedby="password"
                (keydown)="setPasswordVisibility(!isPasswordShown)"
                (mousedown)="setPasswordVisibility(!isPasswordShown)"
                (touchstart)="setPasswordVisibility(!isPasswordShown)"
                appTooltip="{{ 'show_password' | translate }}"
                class="show-password-button btn-icon text-center"
                [ngClass]="{ pressed: isPasswordShown }"
              >
                <svg viewBox="0 0 8 8" [ngClass]="{ 'pass-visible': isPasswordShown === true }">
                  <use xlink:href="#eye"></use>
                </svg>
                <span class="visuallyhidden">
                  {{ isPasswordShown ? 'hide_password' : ('show_password' | translate) }}
                </span>
              </button>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" id="invalid-password">
            <app-form-field-error
              [formValidationRequest$]="formValidationRequest$"
              [field]="form.controls['password']"
              [name]="'password'"
              [label]="'password'"
            ></app-form-field-error>
          </div>
        </div>

        <div class="row margin-bottom-lg">
          <div class="col-md-12">
            <p class="m-0">
              <a routerLink="/password-reset">
                {{ 'reset_password_instructions' | translate }}
              </a>
            </p>
          </div>
        </div>

        <div class="row margin-bottom">
          <div class="col-md-12 text-end">
            <button type="submit" class="btn-login btn-lg w-100 justify-content-center" [disabled]="form.disabled">
              {{ 'button_login' | translate }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </ng-container>
  <ng-container *ngIf="isMfaRequired === true">
    <app-mfa-login></app-mfa-login>
  </ng-container>
</app-login-card>
