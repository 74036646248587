<ng-container *ngIf="visible && (message || isRawHtml)">
  <div class="banner banner-{{ type }} w-100 d-flex align-items-center justify-content-between gap-2" role="alert">
    <div class="banner-icon-text d-flex align-items-center justify-content-between gap-2">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="BannerType.success">
          <ds-icon [icon]="Icons.Check"></ds-icon>
        </ng-container>
        <ng-container *ngSwitchCase="BannerType.info">
          <ds-icon [icon]="Icons.CircleInfo"></ds-icon>
        </ng-container>
        <ng-container *ngSwitchCase="BannerType.error">
          <ds-icon [icon]="Icons.CircleExclamation"></ds-icon>
        </ng-container>
        <ng-container *ngSwitchCase="BannerType.warning">
          <ds-icon [icon]="Icons.TriangleExclamation"></ds-icon>
        </ng-container>
      </ng-container>
      <div *ngIf="!isRawHtml; else projection" class="flex-grow-1 text-break">
        <div *ngIf="isHtml" [innerHTML]="message"></div>
        <ng-container *ngIf="!isHtml">{{ message }}</ng-container>
      </div>
      <ng-template #projection>
        <ng-content></ng-content>
      </ng-template>
    </div>
    <ng-container *ngIf="closeable">
      <button
        class="close-button"
        ds-button
        [variant]="DsButtonVariants.microAction"
        [microActionIcon]="Icons.XMark"
        [attr.aria-label]="'label_close' | translate"
        (click)="closeBanner()"
      ></button>
    </ng-container>
  </div>
</ng-container>
