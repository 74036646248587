import { RestVersion } from '../interfaces/rest.interface';
import constants from '../../environments/constants.config.json';
import { Api } from '../../../shared/constants/api';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { errorMessagesNames } from '../../../shared/constants/errors';
import { FeatureFlagCollection } from '../../../shared/interfaces/feature-flag.interface';

export const IE_REVEAL_EYE_SELECTOR: string = 'input::-ms-reveal';

export enum EnvironmentType {
  Dev = 1,
  QA = 2,
  Stage = 3,
  Prod = 4,
}

export const ApiVersion: RestVersion = {
  current: 'v1',
  v1: 'v1',
};

export const DEFAULT_VALIDATION_BOUNCE_TIME: number = 300;
export const DEFAULT_A11Y_ALERT_MESSAGE_BOUNCE_TIME: number = DEFAULT_VALIDATION_BOUNCE_TIME + 100;
export const DEFAULT_SCROLL_HEIGHT: number = 64;
export const A11Y_MESSAGE_TIMEOUT_IN_MILLIS: number = 1000;

export enum ActivatedRouteData {
  title = 'title',
  description = 'description',
  sessionType = 'sessionType',
  uiConfig = 'uiConfig',
  featureFlag = 'featureFlag',
  redirectTo = 'redirectTo',
}

export enum ScanAction {
  Cancel = 'Cancel',
  Remove = 'Remove',
  ReRun = 'ReRun',
  Resume = 'Resume',
}

export enum redirectUrl {
  returnTo = 'returnTo',
}

export const REDIRECT_TO_USERWAY_DASHBOARD_PARAM_VAL: string = 'external-userway-dashboard';

export enum viewportSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
}

export enum scansPagination {
  default = 10,
}

export enum RouterLinkTarget {
  SELF = '_self',
  BLANK = '_blank',
}

// Note: these error message must be synchronised with same list on the backend side
export const RESPONSE_ERROR_MESSAGES: { [key: string]: string } = {
  CastError: 'error_action_cant_be_performed',
  [errorMessagesNames.DisplayNameExists]: 'error_display_name_already_exists',
  [errorMessagesNames.ErrorCantRemoveWorkspaceWithUsers]: 'error_while_removing_workspace_with_users',
  [errorMessagesNames.ErrorProcessingScanRequest]: 'error_while_processing_scan_request',
  IncorrectUsernameOrPassword: 'error_user_not_found', // It looks like this key is unreachable
  [errorMessagesNames.InvalidCurrentPassword]: 'error_invalid_current_password',
  [errorMessagesNames.ErrorScanTimeout]: 'error_scan_timeout',
  [errorMessagesNames.NoAccessRefreshTokenProvided]: 'error_no_authorization',
  TimeoutError: 'error_scan_timeout', // It looks like this key is unreachable
  TokenExpiredError: 'error_token_expired', // It looks like this key is unreachable
  [errorMessagesNames.UserDisabled]: 'error_user_disabled',
  UserNotFound: 'error_user_not_found', // It looks like this key is unreachable
  [errorMessagesNames.CannotDeleteDigitalPropertyWithData]: 'cannot_delete_digital_property_with_data',
  [errorMessagesNames.ErrorIncorrectOrExpiredMfaToken]: 'mfa_code_is_not_correct_or_expired',
  [errorMessagesNames.AcademyInitializationFailed]: 'academy_integration_error_message',
  [errorMessagesNames.AcademyIntegrationError]: 'academy_integration_error_message',
  [errorMessagesNames.AcademySeatLimitExceeded]: 'academy_seat_limit_exceeded_message',
  [errorMessagesNames.AcademyUpdateFailed]: 'academy_integration_error_message_when_updating',
};

// in ms
export enum PULLING_INTERVAL {
  FAST = constants.pollingInterval.fast,
  SLOW = constants.pollingInterval.slow,
  SLOWER = constants.pollingInterval.slower,
}

export const DEBOUNCE_TIME: Readonly<number> = 200;

export const ROUTES_TO_APPLY_UI_CONFIG: readonly string[] = SharedCommonUtility.freezeDeep([
  `/${Api.integrations}/${Api.connect_browser_extension}`,
]);

export const enum $pageColumns {
  url = 'url',
  pageScore = 'pageScore',
  score = 'score',
  open = 'open',
  dismissed = 'dismissed',
  totalFailed = 'totalFailed',
  totalReview = 'totalReview',
  action = 'action',
}

export const focusableElementSelectors: string[] = [
  'a[href]',
  'button',
  'textarea',
  'input[type="text"]',
  'input[type="radio"]',
  'input[type="checkbox"]',
  'select',
];

export const GlobalFeatureFlagDefaults: { [key: string]: boolean } = {
  [FeatureFlagCollection.scimFunctionality]: false,
  [FeatureFlagCollection.mobileAppScans]: false,
};
