import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { CommonUtility } from '../../utility/common.utility';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { SharedTextUtility } from '../../../../shared/utils/text.utility';
import { $digitalPropertyKeyRegExp } from '../../../../shared/constants/digital-properties';
import { validationError } from '../../constants/form.constants';
import { IDigitalProperty } from '../../../../shared/interfaces/digital-property.interface';
import { MimeTypeToFileExtension, SupportedImageMimeType } from '../../../../shared/constants/mime-type';
import { minPasswordLength } from '../../../../shared/constants/user';
import { IUploadClient } from '../../../../shared/interfaces/uploads.interface';
import { subdomainPattern } from '../../../../shared/constants/tenant';
import { isUpload } from '../../components/common-drag-and-drop-files/common-drag-and-drop-files.component';
import { DESIGN_RULE_DISALLOWED_CHARS, DESIGN_RULE_ID_PATTERN } from '../../../../shared/constants/design-rule';
import { DomUtility } from '../../utility/dom.utility';

// Note: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/

export const validators: any = {
  MIN_PASSWORD_LENGTH: minPasswordLength,
  hasWhiteSpacesPattern: new RegExp('\\s'),
  imageFileExtensions: [...SupportedImageMimeType.map((mimeType: string) => MimeTypeToFileExtension[mimeType]), '.jpg'],
  subdomainPattern,
};

export class CustomValidators extends Validators {
  private static validateCustomFunction(
    domainControl: AbstractControl,
    validationFunction: (input: string) => boolean,
  ): ValidationErrors | null {
    const domain: string = domainControl.value;

    const isInvalid = (_url: string): boolean => {
      return validationFunction(_url) === false;
    };

    const invalidDomains: string[] = SharedTextUtility.getLinesFromText(domain).filter(isInvalid);

    if (invalidDomains.length === 0) {
      return null;
    }

    return { isDomainValid: invalidDomains.map((s: string): string => `"${s}"`).join(', ') };
  }

  private static checkDuplicate(
    existingValues: string[] | null,
    control: UntypedFormControl,
    caseSensitive: boolean = false,
  ): boolean {
    if (typeof control.value !== 'string') {
      return false;
    }

    const value: string = caseSensitive ? control.value.trim() : control.value.trim().toLowerCase();

    if (value.length === 0) {
      return false;
    }

    const valuesSet: Set<string> =
      existingValues === null
        ? null
        : new Set(
            (caseSensitive && existingValues) ||
              (Array.isArray(existingValues) && existingValues.map((i: string): string => i.toLowerCase())) ||
              existingValues,
          );
    return valuesSet === null || valuesSet.has(value);
  }

  public static validateHasUppercase(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const isValid = new RegExp(`[A-Z]`).test(control.value);
      return isValid ? null : { noUppercase: true };
    }

    return null;
  }

  public static validateHasLowercase(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const isValid = new RegExp(`[a-z]`).test(control.value);
      return isValid ? null : { noLowercase: true };
    }

    return null;
  }

  public static validateHasNumberOrSpecial(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const isValid = new RegExp(`\\d|\\W`).test(control.value);
      return isValid ? null : { noNumberOrSpecial: true };
    }

    return null;
  }

  public static validateWhiteSpaces(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const hasWhiteSpaces = validators.hasWhiteSpacesPattern.test(control.value);
      return hasWhiteSpaces ? { hasWhiteSpaces: true } : null;
    }

    return null;
  }

  public static validateIsEmpty(control: UntypedFormControl): ValidationErrors | null {
    let isEmpty: boolean = true;

    if (CommonUtility.isHtmlElement((control as any).nativeElement) && (control as any).nativeElement.type === 'file') {
      isEmpty = ((control as any).nativeElement as HTMLInputElement).files.length === 0;
    } else if (typeof control.value === 'string') {
      isEmpty = control.value.trim().length === 0;
    } else if (Array.isArray(control.value)) {
      isEmpty = control.value.length === 0;
    } else if (typeof control.value === 'number') {
      isEmpty = Number.isNaN(control.value);
    } else if (typeof control.value !== 'undefined' && control.value !== null) {
      isEmpty = false;
    }

    return isEmpty ? { isEmpty: true } : null;
  }

  public static archiveConfirmationMessage(
    confirmationMessage: string,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      if (control.value !== confirmationMessage) {
        return {
          invalidArchiveConfirmationMessage: true,
        };
      }

      return null;
    }

    return customValidator;
  }

  public static validateIsFileType(
    fileType: string,
    readableFileType: string,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      if (Array.isArray(control.value) && control.value.every((f: unknown): boolean => f instanceof File)) {
        return control.value.every((f: File): boolean => f.type === fileType) ? null : { invalidFileType: readableFileType };
      }
      return { invalidFileType: readableFileType };
    }
    return customValidator;
  }

  public static validateAllEmailsInText(control: UntypedFormControl): ValidationErrors | null {
    const lines: string[] = SharedTextUtility.getLinesFromText(control.value);

    if (lines.length > 0) {
      return lines.every(SharedCommonUtility.isValidEmail) ? null : { invalidEmail: true };
    }

    return null;
  }

  public static validateEmailsInTextSplitBy(separator: string): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      if (SharedCommonUtility.notNullishOrEmpty(control.value)) {
        const substrings: string[] = control.value.split(separator);

        if (substrings.length > 0) {
          return substrings.every(SharedCommonUtility.isValidEmail) ? null : { invalidEmail: true };
        }
      }
      return null;
    };
  }

  public static validateEmail(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const isEmailValid: boolean = SharedCommonUtility.isValidEmail(control.value);
      return isEmailValid ? null : { invalidEmail: true };
    }

    return null;
  }

  public static validateAtLeastOneSelected(control: UntypedFormArray): ValidationErrors | null {
    if (Array.isArray(control.value) === false || control.value.length === 0) {
      return { noSelection: true };
    }

    const oneSelected: boolean = control.value.some((selected: boolean) => selected);
    return oneSelected ? null : { noSelection: true };
  }

  // Helper function for uniqueValidator to normalize the value based on
  // optional case/space insensitive params
  private static uniquenessNormalizer(value: string, caseInsensitive: boolean, extraWhitespaceInsensitive: boolean): string {
    let normalizedValue: string = value;
    if (caseInsensitive) {
      normalizedValue = normalizedValue.toLowerCase();
    }

    if (extraWhitespaceInsensitive) {
      normalizedValue = normalizedValue.trim().replace(/\s\s+/g, ' ');
    }

    return normalizedValue;
  }

  public static uniqueValidator(
    checkSet: Set<string>,
    caseInsensitive: boolean = false,
    // Reduces extra whitespace to just 1 space
    // eg- “home page” is equal to “home   page”
    extraWhitespaceInsensitive: boolean = false,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    const normalizedCheckSet: Set<string> = new Set(
      [...checkSet].map((item: string): string => this.uniquenessNormalizer(item, caseInsensitive, extraWhitespaceInsensitive)),
    );

    return (control: UntypedFormControl): ValidationErrors | null => {
      const normalizedValue: string = this.uniquenessNormalizer(
        String(control.value),
        caseInsensitive,
        extraWhitespaceInsensitive,
      );

      if (control.value && normalizedCheckSet.has(normalizedValue)) {
        return { unique: true };
      }
      return null;
    };
  }

  public static in(set: Set<string>, caseInsensitive: boolean = false): (control: AbstractControl) => ValidationErrors | null {
    let normalizedSet: Set<string> = set;
    if (caseInsensitive) {
      const lowerCaseItems: string[] = [...set].map((item: string): string => item.toLowerCase());
      normalizedSet = new Set(lowerCaseItems);
    }
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      let normalizedValue = control.value;
      if (typeof normalizedValue === 'string' && caseInsensitive === true) {
        normalizedValue = normalizedValue.toLowerCase();
      }
      if (normalizedSet.has(normalizedValue) === false) {
        return { [validationError.in]: true };
      }
      return null;
    };
  }

  public static isValidDate(control: AbstractControl): ValidationErrors {
    if (control.value !== null && typeof control.value !== 'undefined' && control.value !== '') {
      const controlValue: Date = typeof control.value === 'string' ? new Date(control.value) : control.value;
      if (SharedCommonUtility.isDateValid(controlValue)) {
        return null;
      }
      return { invalidDate: true };
    }
    return null;
  }

  public static charactersOnly(control: AbstractControl): ValidationErrors | null {
    if (control.value && /^[a-zA-Z]+$/.test(control.value) === false) {
      return { [validationError.charactersOnly]: true };
    }
    return null;
  }

  public static alphaNumericCharactersOnly(control: AbstractControl): ValidationErrors | null {
    if (control.value && /^[a-zA-Z0-9]+$/.test(control.value) === false) {
      return { [validationError.alphaNumericCharactersOnly]: true };
    }
    return null;
  }

  public static nonEmojiCharactersOnly(control: AbstractControl): ValidationErrors | null {
    if (control.value && DESIGN_RULE_DISALLOWED_CHARS.test(control.value) === true) {
      return { [validationError.nonEmojiCharactersOnly]: true };
    }
    return null;
  }

  /**
   * This function takes in a string and will validate if any of those characters in the abstract control contains
   * characters in the exclude string
   *
   * @param exclude string of characters to check against
   */
  public static excludeCharacters(exclude: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const excludedCharacterString: string = SharedTextUtility.escapeRegExpString(exclude);
      const regex: RegExp = new RegExp(`[${excludedCharacterString}]`);

      if (control.value && regex.test(control.value) === true) {
        return { excludeCharacters: Array.from(exclude).join(' ') };
      }
      return null;
    };
  }

  public static designRuleIdPattern(control: AbstractControl): ValidationErrors | null {
    if (control.value && DESIGN_RULE_ID_PATTERN.test(control.value) === false) {
      return { [validationError.designRuleIdPattern]: true };
    }
    return null;
  }

  public static minDate(value: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue: Date = typeof control.value === 'string' ? new Date(control.value) : control.value;
      if (SharedCommonUtility.isDateValid(value) && SharedCommonUtility.isDateValid(controlValue)) {
        if (controlValue.getTime() - value.getTime() < 0) {
          return { minDate: true };
        }
        return null;
      }
      return null;
    };
  }

  public static maxDate(value: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue: Date = typeof control.value === 'string' ? new Date(control.value) : control.value;
      if (SharedCommonUtility.isDateValid(value) && SharedCommonUtility.isDateValid(controlValue)) {
        if (controlValue.getTime() - value.getTime() > 0) {
          return { maxDate: true };
        }
        return null;
      }
      return null;
    };
  }

  public static compareDateControls(control: AbstractControl, compareWith: AbstractControl): number | null {
    if (SharedCommonUtility.isNullish(control.value) || SharedCommonUtility.isNullish(compareWith.value)) {
      return null;
    }
    const controlDate: Date = new Date(control.value);
    const controlDateValid: boolean = SharedCommonUtility.isDateValid(controlDate);
    const compareDate: Date = new Date(compareWith.value);
    const compareDateValid: boolean = SharedCommonUtility.isDateValid(compareDate);
    if (control.value && controlDateValid && compareDateValid) {
      return controlDate.getTime() - compareDate.getTime();
    }
    return null;
  }

  public static datesValidator(startFromControl: string, endToControl: string): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const result: number = this.compareDateControls(group.get(startFromControl), group.get(endToControl));
      if (result === null) {
        return null;
      }
      if (result > 0) {
        group.controls[startFromControl].setErrors({ [validationError.datesValidator]: true });
        return { [validationError.datesValidator]: true };
      }
      if (group.controls[startFromControl].errors?.[validationError.datesValidator]) {
        delete group.controls[startFromControl].errors[validationError.datesValidator];
        group.controls[startFromControl].updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }

  public static domainValidator(domainControl: AbstractControl): ValidationErrors | null {
    return CustomValidators.validateCustomFunction(domainControl, (value: string) => {
      const trimAndLower = (_domain: string): string => {
        return SharedCommonUtility.removeProtocolFromUrl(_domain.trim().toLowerCase());
      };

      return SharedTextUtility.isValidDomain(trimAndLower(value));
    });
  }

  public static domainWithoutProtocolValidator(domainControl: AbstractControl): ValidationErrors | null {
    return SharedTextUtility.isValidDomain(domainControl.value?.trim().toLocaleLowerCase())
      ? null
      : { domainWithoutProtocolValidator: true };
  }

  public static urlValidator(urlControl: AbstractControl): ValidationErrors | null {
    return CustomValidators.validateCustomFunction(urlControl, (value: string) => {
      try {
        const normalizedUrl: string = SharedCommonUtility.getNormalizedUrlString(value);
        return CommonUtility.isValidUrl(normalizedUrl);
      } catch (_) {
        return false;
      }
    });
  }

  public static isValidUrl(urlControl: AbstractControl): ValidationErrors | null {
    return CustomValidators.validateCustomFunction(urlControl, (value: string) => CommonUtility.isValidUrl(value));
  }

  public static isValidJiraFieldUrl(urlControl: AbstractControl): ValidationErrors | null {
    return CustomValidators.validateCustomFunction(urlControl, (value: string) => CommonUtility.isValidJiraFieldUrl(value));
  }

  public static domainRestrictionsValidator(
    domainsRegexp: RegExp[] | (() => RegExp[]),
    errorCode: string = 'isDomainPartOfSubscription',
  ): (control: UntypedFormControl) => ValidationErrors | null {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const urls: string[] = SharedTextUtility.getLinesFromText(control.value);

      const domains: RegExp[] = typeof domainsRegexp === 'function' ? domainsRegexp() : domainsRegexp;
      if (urls.some((url: string): boolean => SharedCommonUtility.isUrlAuthorized(url, domains) === false)) {
        return { [errorCode]: true };
      }

      return null;
    };
  }

  public static digitalPropertyKeyValidator(control: UntypedFormControl): ValidationErrors | null {
    if (typeof control.value !== 'string') {
      return null;
    }

    const value: string = control.value.trim();
    if (value.length === 0) {
      return null;
    }

    return $digitalPropertyKeyRegExp.test(value) ? null : { [validationError.digitalPropertyKey]: true };
  }

  public static sitemapValidator(
    domainsRegexp: () => RegExp[],
    errorCode: string = 'isDomainPartOfSubscription',
  ): (control: UntypedFormControl) => ValidationErrors | null {
    return (control: UntypedFormControl): ValidationErrors | null => {
      return CustomValidators.domainRestrictionsValidator(domainsRegexp, errorCode)(control);
    };
  }

  /**
   *
   * @param values <code>null</code> is considered as "invalid" state
   * @param key key to be passed to the error message
   */
  public static digitalPropertyFieldWorkspaceDuplicateValidator(
    values: string[] | null,
    key: keyof IDigitalProperty,
    caseSensitive: boolean = false,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    return (control: UntypedFormControl): ValidationErrors => {
      if (this.checkDuplicate(values, control, caseSensitive)) {
        return { [validationError.digitalPropertyFieldWorkspaceDuplicate]: key };
      }

      return null;
    };
  }

  public static ruleIdDuplicatedValidator(
    existingValues: string[] | null,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    return (control: UntypedFormControl): ValidationErrors => {
      if (this.checkDuplicate(existingValues, control, true)) {
        return { [validationError.masterLibraryRuleIdDuplicate]: true };
      }

      return null;
    };
  }

  public static limitMaxNumberOfLines(_limit: number | (() => number), errorKey: string = 'maxLines'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const limit: number = typeof _limit === 'function' ? _limit() : _limit;
      const lines: string[] = control.value.split('\n').filter((lineContent: string) => lineContent.length > 0);
      if (lines.length > limit) {
        return { [errorKey]: limit };
      }
      return null;
    };
  }

  public static userWayWebsiteDuplicatedValidator(
    existingValues: Set<string> | null,
  ): (control: UntypedFormControl) => ValidationErrors | null {
    return (control: UntypedFormControl): ValidationErrors => {
      const value: string = control.value?.url;

      if (SharedCommonUtility.isNullishOrEmpty(value)) {
        return null;
      }

      if (existingValues?.has(SharedCommonUtility.getDomainPattern(value)[0])) {
        return { [validationError.userWayWebsiteDuplicate]: true };
      }

      return null;
    };
  }

  public static validateScanTagSelected(control: UntypedFormControl): ValidationErrors | null {
    const isNotEqual: boolean = SharedCommonUtility.notNullish(control.value);

    return isNotEqual ? null : { noTagSelected: true };
  }

  public static validateConformanceLevelSelected(control: UntypedFormControl): ValidationErrors | null {
    const isNotEqual: boolean = SharedCommonUtility.notNullish(control.value);

    return isNotEqual ? null : { noConformanceLevelSelected: true };
  }

  public static validateWorkspaceSelected(control: UntypedFormControl): ValidationErrors | null {
    const isNotEqual: boolean = control.value !== null;

    return isNotEqual ? null : { noWorkspaceSelected: true };
  }

  public static validateSingleFileIsImage(control: UntypedFormControl): ValidationErrors | null {
    const fileExt: string = control.value?.split('.').pop();
    if (fileExt && validators.imageFileExtensions.includes(`.${fileExt}`) === false) {
      return { invalidFileTypes: validators.imageFileExtensions.join(', ') };
    }
    return null;
  }

  public static validateJSON(control: UntypedFormControl): ValidationErrors | null {
    return SharedCommonUtility.isValidJSON(control.value, true) ? null : { [validationError.invalidJSON]: true };
  }

  public static validateAttachmentFileType(
    selectedAttachments: File[],
    supportedMimeTypes: string[],
    supportedExtensions: string[],
  ): ValidatorFn {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      const files: File[] = SharedCommonUtility.notNullish(selectedAttachments) ? selectedAttachments : control.value ?? [];

      for (const file of files) {
        if (supportedMimeTypes.includes(file.type) === false) {
          const fileExt: string = file.name.split('.').pop();
          // Checking only File.type is not reliable. Firstly, it's just derived from the file extension.
          // Secondly, it still could be empty for well-known file extensions on some machines
          // https://developer.mozilla.org/en-US/docs/Web/API/File/type
          if (supportedExtensions.includes(`.${fileExt}`) === true) {
            continue;
          }
          const supportedFileExtensions: string[] = supportedMimeTypes.map(
            (mimeType: string) => MimeTypeToFileExtension[mimeType],
          );
          return { invalidFileTypes: supportedFileExtensions.join(', ') };
        }
      }
      return null;
    }
    return customValidator;
  }

  public static validateAttachmentTotalSize(selectedAttachments: (File | IUploadClient)[], maxTotalSize: number): ValidatorFn {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      const files: File[] = SharedCommonUtility.notNullish(selectedAttachments) ? selectedAttachments : control.value ?? [];

      let size: number = 0;

      const addFileSize = (attachment: File | IUploadClient): void => {
        if (isUpload(attachment)) {
          size += (attachment as IUploadClient).fileSize;
        } else {
          size += (attachment as File).size;
        }
      };

      files.forEach(addFileSize);

      if (size > maxTotalSize) {
        return { attachmentSizeExceedsLimit: maxTotalSize };
      }
      return null;
    }
    return customValidator;
  }

  public static validateAttachmentQuantity(
    selectedAttachments: (File | IUploadClient)[],
    maxQuantity: number,
    occupiedQuantity?: number,
  ): ValidatorFn {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      if (selectedAttachments.length > maxQuantity - (occupiedQuantity || 0)) {
        return { attachmentQuantityExceedsLimit: maxQuantity };
      }
      return null;
    }
    return customValidator;
  }

  public static validateAttachmentRequired(selectedAttachments: (File | IUploadClient)[]): ValidatorFn {
    function customValidator(control: UntypedFormControl): ValidationErrors | null {
      if (selectedAttachments.length === 0) {
        return {
          required: true,
        };
      }

      return null;
    }
    return customValidator;
  }

  public static validateSubdomain(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const isValid = validators.subdomainPattern.test(control.value);
      return isValid ? null : { invalidSubdomain: true };
    }

    return null;
  }

  public static isNumeric(): ValidatorFn {
    return CustomValidators.pattern('^[0-9]*$');
  }

  public static validateInteger(control: UntypedFormControl): ValidationErrors | null {
    return SharedCommonUtility.isInteger(control.value) ? null : { [validationError.invalidInteger]: true };
  }

  public static validateCssSelector(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== '' && !DomUtility.isCssSelectorValid(control.value)) {
      return { cssSelectorInvalid: true };
    }

    return null;
  }
}
