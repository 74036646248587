export enum MimeTypes {
  JSON = 'application/json',
  TEXT = 'text/plain',
  CSV = 'text/csv',
  XML = 'application/xml',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  POTX = 'application/vnd.openxmlformats-officedocument.presentationml.template',
  PPSX = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOTX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLTX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  PDF = 'application/pdf',
  ODT = 'application/vnd.oasis.opendocument.text',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  BMP = 'image/bmp',
  ICO = 'image/vnd.microsoft.icon',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
  SVG = 'image/svg+xml',
  GIF = 'image/gif',
}

export const MimeTypeToFileType: any = Object.freeze({
  'application/xhtml+xml': 'XHTML',
  'text/css': 'CSS',
  'text/html': 'HTML',
});

export const MimeTypeToFileExtension: { [mimeType in MimeTypes]: string } = Object.freeze({
  [MimeTypes.JSON]: '.json',
  [MimeTypes.TEXT]: '.txt',
  [MimeTypes.CSV]: '.csv',
  [MimeTypes.XML]: '.xml',
  [MimeTypes.PPT]: '.ppt',
  [MimeTypes.PPTX]: '.pptx',
  [MimeTypes.POTX]: '.potx',
  [MimeTypes.PPSX]: '.ppsx',
  [MimeTypes.DOC]: '.doc',
  [MimeTypes.DOCX]: '.docx',
  [MimeTypes.DOTX]: '.dotx',
  [MimeTypes.XLS]: '.xls',
  [MimeTypes.XLSX]: '.xlsx',
  [MimeTypes.XLTX]: '.xltx',
  [MimeTypes.PDF]: '.pdf',
  [MimeTypes.ODT]: '.odt',
  [MimeTypes.ODS]: '.ods',
  [MimeTypes.PNG]: '.png',
  [MimeTypes.BMP]: '.bmp',
  [MimeTypes.WEBP]: '.webp',
  [MimeTypes.ICO]: '.ico',
  [MimeTypes.TIFF]: '.tiff',
  [MimeTypes.JPEG]: '.jpeg',
  [MimeTypes.JPG]: '.jpg',
  [MimeTypes.SVG]: '.svg',
  [MimeTypes.GIF]: '.gif',
});

export const ImageFileExtensions: readonly string[] = Object.freeze([
  MimeTypeToFileExtension[MimeTypes.PNG],
  MimeTypeToFileExtension[MimeTypes.JPG],
  MimeTypeToFileExtension[MimeTypes.JPEG],
  MimeTypeToFileExtension[MimeTypes.GIF],
  MimeTypeToFileExtension[MimeTypes.BMP],
  MimeTypeToFileExtension[MimeTypes.ICO],
  MimeTypeToFileExtension[MimeTypes.TIFF],
  MimeTypeToFileExtension[MimeTypes.SVG],
  MimeTypeToFileExtension[MimeTypes.WEBP],
]);

export const SupportedDocumentMimeType: string[] = [
  MimeTypes.PDF,
  MimeTypes.ODS,
  MimeTypes.ODT,
  MimeTypes.DOC,
  MimeTypes.DOCX,
  MimeTypes.XLS,
  MimeTypes.XLSX,
];

export const SupportedDocumentExtensions = [
  MimeTypeToFileExtension[MimeTypes.PDF],
  MimeTypeToFileExtension[MimeTypes.ODS],
  MimeTypeToFileExtension[MimeTypes.ODT],
  MimeTypeToFileExtension[MimeTypes.DOC],
  MimeTypeToFileExtension[MimeTypes.DOCX],
  MimeTypeToFileExtension[MimeTypes.XLS],
  MimeTypeToFileExtension[MimeTypes.XLSX],
];

export const SupportedManualAuditAttachmentMimeType: string[] = [
  MimeTypes.CSV,
  MimeTypes.XLSX,
  MimeTypes.JPEG,
  MimeTypes.PNG,
  MimeTypes.JSON,
];

export const SupportedManualAuditAttachmentExtensions: string[] = SupportedManualAuditAttachmentMimeType.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedTaskAttachmentMimeType: string[] = [
  MimeTypes.CSV,
  MimeTypes.XLSX,
  MimeTypes.JPEG,
  MimeTypes.PNG,
  MimeTypes.XLS,
];

export const SupportedImageMimeType: string[] = [
  MimeTypes.PNG,
  MimeTypes.BMP,
  MimeTypes.WEBP,
  MimeTypes.ICO,
  MimeTypes.TIFF,
  MimeTypes.JPEG,
];

export const SupportedImageExtensions: string[] = SupportedImageMimeType.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedDesignReviewScreenMimeTypes: string[] = [MimeTypes.PNG];

export const SupportedDesignReviewScreenExtensions: string[] = SupportedDesignReviewScreenMimeTypes.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedScreenshotMimeTypes: MimeTypes[] = [MimeTypes.JPEG, MimeTypes.JPG, MimeTypes.BMP, MimeTypes.PNG];

export const SupportedScreenshotExtensions: string[] = SupportedScreenshotMimeTypes.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedComponentImageMimeTypes: MimeTypes[] = [MimeTypes.JPG, MimeTypes.JPEG, MimeTypes.PNG, MimeTypes.GIF];

export const SupportedComponentImageExtensions: string[] = SupportedComponentImageMimeTypes.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedComponentImageExtensionsSet: Readonly<Set<string>> = new Set(SupportedComponentImageExtensions);

export const SupportedManualAuditUploadMimeType: string[] = [MimeTypes.XLSX, MimeTypes.XLS, MimeTypes.JSON];

export const SupportedManualAuditUploadExtensions: string[] = SupportedManualAuditUploadMimeType.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedManualAuditUploadScreenshotMimeType: string[] = [MimeTypes.PNG, MimeTypes.JPEG, MimeTypes.JPG];

export const SupportedManualAuditUploadScreenshotExtensions: string[] = SupportedManualAuditUploadScreenshotMimeType.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedChangeEmailsMimeType: MimeTypes[] = [MimeTypes.XLSX];

export const SupportedChangeEmailsExtensions: string[] = SupportedChangeEmailsMimeType.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedPageScreenshotMimeTypes: MimeTypes[] = [MimeTypes.JPG, MimeTypes.PNG];

export const SupportedPageScreenshotExtensions: string[] = SupportedPageScreenshotMimeTypes.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);

export const SupportedMobileScreenshotMimeTypes: MimeTypes[] = [MimeTypes.JPG, MimeTypes.JPEG, MimeTypes.PNG];

export const SupportedMobileScreenshotExtensions: string[] = SupportedMobileScreenshotMimeTypes.map(
  (value: MimeTypes): string => MimeTypeToFileExtension[value],
);
