import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { HealthcheckApi } from './rest/healthcheck.api';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { DEBOUNCE_TIME } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckService {
  constructor(private healthcheckApi: HealthcheckApi) {}

  public ping(organizationDomain: string): Observable<void> {
    return this.healthcheckApi.ping(organizationDomain);
  }

  // Checks whether the organization URL corresponds to an actual active tenant
  public get organizationURLAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let val: string = control.value?.trim();

      if (SharedCommonUtility.isNullishOrEmpty(val)) {
        return of(null);
      }

      const [, orgDomain] = SharedCommonUtility.getProtocolHostPortAndPath(val);
      val = `https://${orgDomain}`;

      return of(val).pipe(
        delay(DEBOUNCE_TIME),
        switchMap((orgURL: string) =>
          this.ping(new URL(orgURL).hostname).pipe(
            map((): null => null),
            catchError((_: HttpErrorResponse): Observable<{ invalidOrgURL: boolean }> => of({ invalidOrgURL: true })),
          ),
        ),
      );
    };
  }
}
