export enum $userPreference {
  _id = '_id',
  user = 'user',
  workspace = 'workspace',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  scope = 'scope',
  param = 'param',
  value = 'value',
  projectId = 'projectId',
}

export enum $userPreferenceFilter {
  userId = 'userId',
  scope = 'scope',
  workspaceId = 'workspaceId',
  projectId = 'projectId',
}

export enum userPreferenceScope {
  userWorkspacesActivityWidget = 'userWorkspacesActivityWidget',
  workspaceActivityWidget = 'workspaceActivityWidget',
  manualAuditIssues = 'manualAuditIssues',
  notificationsSettings = 'notificationsSettings',
  projectTasks = 'projectTasks',
  platformUsage = 'platformUsage',
  programDash = 'programDash',
  conformanceReport = 'conformanceReport',
}

export enum userPreferenceScopeParam {
  limit = 'limit',
  filter = 'filter',
  fromDate = 'fromDate',
  selectedColumns = 'selectedColumns',
  issueFilter = 'issueFilter',
  frequency = 'frequency',
  prioritization = 'prioritization',
  notificationTypes = 'notificationTypes',
  platformActions = 'platformActions',
}
