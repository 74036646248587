import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationService } from '../../services/notification.service';
import { NotificationPosition } from '../../models/notification.model';
import { ScanService } from '../../services/scan.service';
import { IScanActionError } from '../../interfaces/scan.interface';
import { TranslateService } from '../../translate/translate.service';
import { completedStatuses, cannotRerunScanningTypes, scanningType, scanStatus } from '../../../../shared/constants/scanning';
import { ScanAction } from '../../shared/constants';
import { Api } from '../../../../shared/constants/api';
import { IScan } from '../../../../shared/interfaces/scan.interface';
import { $scan, SCAN_URLS_SIZE_LIMIT } from '../../../../shared/constants/scan';
import { WindowService } from '../../services/window.service';
import { AclSecurityAdapter } from '../../services/acl.service';
import { UserAclService } from '../../services/user-acl.service';
import { RequiredSecurities } from '../../../../shared/constants/required-securities';

@Component({
  selector: 'app-scan-actions',
  templateUrl: './scan-actions.component.html',
  styleUrls: ['./scan-actions.component.scss'],
})
export class ScanActionsComponent implements OnDestroy {
  private subscription: Subscription;
  @Input() public scan: IScan;
  @Output() public onScanActionSuccess: EventEmitter<ScanAction>;
  @Output() public onScanActionError: EventEmitter<IScanActionError>;

  public scanStatus: typeof scanStatus;
  public Api: typeof Api;

  constructor(
    private translateService: TranslateService,
    private scanService: ScanService,
    private notificationService: NotificationService,
    private windowService: WindowService,
    private userAclService: UserAclService,
  ) {
    this.subscription = new Subscription();
    this.onScanActionSuccess = new EventEmitter();
    this.onScanActionError = new EventEmitter();
    this.scanStatus = scanStatus;
    this.Api = Api;
  }

  private handleError(message: string, response: HttpErrorResponse): void {
    const errorDetails: IScanActionError = {
      message: message,
      response: response,
    };

    this.onScanActionError.emit(errorDetails);
  }

  public removeScan(): void {
    const onRemoveScanSuccess = (): void => {
      this.notificationService.success(
        this.translateService.instant('remove_scan_success_body', [this.scan.url]),
        NotificationPosition.Toast,
      );
      this.onScanActionSuccess.emit(ScanAction.Remove);
    };

    const onRemoveScanError = (response: HttpErrorResponse): void => {
      this.handleError('remove_scan_error', response);
    };

    const isConfirmed: boolean = this.windowService.confirm(this.translateService.instant('remove_scan_confirmation'));

    if (isConfirmed === false) {
      return;
    }

    this.subscription.add(
      this.scanService
        .removeScan(this.scan.workspace.toString(), this.scan[$scan.digitalProperty] as string, this.scan._id)
        .subscribe(onRemoveScanSuccess, onRemoveScanError),
    );
  }

  public canRerunScan(): boolean {
    if (cannotRerunScanningTypes.includes(this.scan[$scan.scanningType])) {
      return false;
    }

    return this.scan.status === this.scanStatus.completed || this.scan.status === this.scanStatus.completed_with_errors;
  }

  public get canRemoveScan$(): Observable<boolean> {
    return this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useWorkspaceFromUser()
            .useDigitalPropertyFromUser()
            .useFunctionalActions(RequiredSecurities.AT_Scans_Delete.functionalActions)
            .check(),
        ),
      );
  }

  public rerunScan(): void {
    const onRerunScanSuccess = (): void => {
      this.notificationService.success(
        this.translateService.instant('rerun_scan_success_body', [this.scan.url]),
        NotificationPosition.Toast,
      );
      if (this.scan.scanningType === scanningType.entireSite && this.scan.limitCrawlingPagesTo > SCAN_URLS_SIZE_LIMIT) {
        this.notificationService.info({
          message: this.translateService.instant('rerun_scan_limit_size_of_url_entireSite', SCAN_URLS_SIZE_LIMIT),
        });
      }
      if (this.scan.scanningType === scanningType.customLinks && this.scan.customLinks.length > SCAN_URLS_SIZE_LIMIT) {
        this.notificationService.info({
          message: this.translateService.instant('rerun_scan_limit_size_of_url_customLinks', SCAN_URLS_SIZE_LIMIT),
        });
      }
      this.onScanActionSuccess.emit(ScanAction.ReRun);
    };

    const onRerunScanError = (response: HttpErrorResponse): void => {
      this.handleError('rerun_scan_error', response);
    };

    this.subscription.add(
      this.scanService
        .rerunScan(this.scan.workspace.toString(), this.scan[$scan.digitalProperty] as string, this.scan._id)
        .subscribe(onRerunScanSuccess, onRerunScanError),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isScanCompleted(): boolean {
    return this.scan && completedStatuses.includes(this.scan[$scan.status]);
  }
}
