import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { IWorkspaceUserData, IWorkspaceUserInfo } from '../../../../../shared/interfaces/workspace-user.interface';
import { TranslateService } from '../../../translate/translate.service';
import { $workspaceUser } from '../../../../../shared/constants/workspace-user.constants';
import { UserService } from '../../../services/user.service';
import { $user, accountStatus } from '../../../../../shared/constants/user';
import { LoadErrorHandler } from '../../../services/load-error-handler';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-workspace-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceUsersListComponent implements OnDestroy, OnChanges {
  private subscription: Subscription;

  @Output() public onStaffUsersFetch: EventEmitter<IWorkspaceUserData[]>;
  @Input() public workspaceId: string;

  public maxDisplayedUsers: Readonly<number>;
  public usersCount: number;
  public usersToDisplay?: IWorkspaceUserData[];
  public hiddenUsers?: IWorkspaceUserData[];
  public $user: typeof $user;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private loadErrorHandler: LoadErrorHandler,
  ) {
    this.onStaffUsersFetch = new EventEmitter<IWorkspaceUserData[]>();
    this.$user = $user;
    this.subscription = new Subscription();
    this.usersCount = 0;
    this.maxDisplayedUsers = 5;
  }

  private onGetWorkspaceUsersSuccess(response: IWorkspaceUserInfo[]): void {
    const staffUsers: IWorkspaceUserData[] = [];
    const usersData: IWorkspaceUserData[] = response
      .filter((user: IWorkspaceUserInfo): boolean => {
        const res: boolean =
          Array.isArray(user.roles) === false ||
          user.roles.filter((role: { name: string }): boolean => role.name === 'workspaceStaff').length === 0;
        if (res === false) {
          staffUsers.push(user[$workspaceUser.user]);
        }
        return res;
      })
      .map((user: IWorkspaceUserInfo) => user[$workspaceUser.user])
      .filter((user: IWorkspaceUserData): boolean => SharedCommonUtility.getTypeOf(user) === 'object');

    this.onStaffUsersFetch.emit(staffUsers);

    this.usersCount = usersData.length;

    this.usersToDisplay = usersData.slice(0, this.maxDisplayedUsers);
    this.hiddenUsers = usersData.splice(this.maxDisplayedUsers);

    this.changeDetectorRef.detectChanges();
  }

  private getData(): void {
    this.subscription.add(
      // TODO: EAP-28329, We need a new API to load workspace users in Admin portal
      of([]).subscribe({
        next: this.onGetWorkspaceUsersSuccess.bind(this),
        error: (error: HttpErrorResponse) => {
          this.loadErrorHandler.handleError('unable_to_get_all_workspace_users', error, WorkspaceUsersListComponent.name);
        },
      }),
    );
  }

  public getUserAvatarUrl(user: IWorkspaceUserData): string {
    return this.userService.getAvatar(user);
  }

  public getDisplayName(user: IWorkspaceUserData): string {
    if (user[$user.accountStatus] === accountStatus.active) {
      return user[$user.displayName];
    }

    return `${user[$user.displayName]} (${this.translateService.instant('status_inactive')})`;
  }

  public focusUsersListPopover(): void {
    (document.querySelector('.popover-body > div') as HTMLButtonElement).focus();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.workspaceId === 'undefined') {
      return;
    }

    this.getData();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
