export enum errorMessagesNames {
  AcademyInitializationFailed = 'AcademyInitializationFailed',
  AcademyUpdateFailed = 'AcademyUpdateFailed',
  AcademyIntegrationError = 'AcademyIntegrationError',
  AcademyAPICallError = 'AcademyAPICallError',
  AcademySeatLimitExceeded = 'AcademySeatLimitExceeded',
  ApiKeyIsExpired = 'ApiKeyIsExpired',
  AuditFindingOutdated = 'AuditFindingOutdated',
  AuditFindingModifiedByOthers = 'AuditFindingModifiedByOthers',
  CannotDeleteLastDigitalProperty = 'CannotDeleteLastDigitalProperty',
  CannotMoveScanDomainNotAllowed = 'CannotMoveScanDomainNotAllowed',
  ConfigPropertyNotProvided = 'ConfigPropertyNotProvided',
  CannotDeleteDigitalPropertyWithData = 'CannotDeleteDigitalPropertyWithData',
  DisplayNameExists = 'DisplayNameExists',
  DigitalPropertyInvalidDomains = 'DigitalPropertyInvalidDomains',
  DigitalPropertyMonitoringInvalidDomains = 'DigitalPropertyMonitoringInvalidDomains',
  DigitalPropertyMonitoringInvalidThreshold = 'DigitalPropertyMonitoringInvalidThreshold',
  DigitalPropertyMonitoringInvalidUserIds = 'DigitalPropertyMonitoringInvalidUserIds',
  DigitalPropertyMonitoringHasDuplicateUserIds = 'DigitalPropertyMonitoringHasDuplicateUserIds',
  DigitalPropertyMonitoringContainsNonPlatformUsers = 'DigitalPropertyMonitoringContainsNonPlatformUsers',
  DigitalPropertyMonitoringInvalidAlertType = 'DigitalPropertyMonitoringInvalidAlertType',
  MonitoringInvalidDomainSitemap = 'MonitoringInvalidDomainSitemap',
  DownloadResourceNotAuthorized = 'DownloadResourceNotAuthorized',
  EmptyFile = 'EmptyFile',
  ErrorUpdatingPageSummary = 'ErrorUpdatingPageSummary',
  ErrorMissingJiraIssueType = 'ErrorMissingJiraIssueType',
  ErrorUpdatingScanSummary = 'ErrorUpdatingScanSummary',
  ErrorScanIsLocked = 'ErrorScanIsLocked',
  ErrorAnalyticsForNonMonitoringScan = 'ErrorAnalyticsForNonMonitoringScan',
  ErrorAuditExecutionFailed = 'ErrorAuditExecutionFailed',
  ErrorApiKeyDuplicateName = 'ErrorApiKeyDuplicateName',
  ErrorApiKeyMaxExceeded = 'ErrorApiKeyMaxExceeded',
  ErrorApiKeyFailedCreateRandom = 'ErrorApiKeyFailedCreateRandom',
  ErrorCannotRenameSystemTag = 'ErrorCannotRenameSystemTag',
  ErrorCannotDeleteSystemTag = 'ErrorCannotDeleteSystemTag',
  ErrorCannotDeleteTagInUse = 'ErrorCannotDeleteTagInUse',
  ErrorTagNameTooLong = 'ErrorTagNameTooLong',
  ErrorInvalidViewportSize = 'ErrorInvalidViewportSize',
  ErrorCannotAddUserToClosedPrivateWorkspace = 'ErrorCannotAddUserToClosedPrivateWorkspace',
  ErrorAddingUserAsWorkspaceStaffIsForbidden = 'ErrorAddingUserAsWorkspaceStaffIsForbidden',
  ErrorCantRemoveWorkspaceWithUsers = 'ErrorCantRemoveWorkspaceWithUsers',
  ErrorCantRemoveLastTenantWorkspace = 'ErrorCantRemoveLastTenantWorkspace',
  ErrorCheckUrlFormAuthCredentialsDuplication = 'ErrorCheckUrlFormAuthCredentialsDuplication',
  ErrorCreationFalsePositiveError = 'ErrorCreationFalsePositiveError',
  ErrorDashboardScanReportInvalidStatus = 'ErrorDashboardScanReportInvalidStatus',
  ErrorDeactivatingUser = 'ErrorDeactivatingUser',
  ErrorDeleteRole = 'ErrorDeleteRole',
  ErrorUserEmailUnverified = 'ErrorUserEmailUnverified',
  CannotUpdateDigitalPropertyKey = 'CannotUpdateDigitalPropertyKey',
  CannotUpdateProjectKey = 'CannotUpdateProjectKey',
  CannotRemoveActiveUserData = 'CannotRemoveActiveUserData',
  CreateFindingForDisabledRule = 'CreateFindingForDisabledRule',
  InvalidCreateFindingRequest = 'InvalidCreateFindingRequest',
  UpdateFindingForDisabledRule = 'UpdateFindingForDisabledRule',
  DuplicatedDigitalPropertyKey = 'DuplicatedDigitalPropertyKey',
  DuplicatedDigitalPropertyName = 'DuplicatedDigitalPropertyName',
  ErrorDigitalPropertyNotFound = 'ErrorDigitalPropertyNotFound',
  ErrorDigitalPropertyManualAuditNameDuplicated = 'ErrorDigitalPropertyManualAuditNameDuplicated',
  ErrorCommentNotFound = 'ErrorCommentNotFound',
  ErrorDigitalPropertyTypesNotFound = 'ErrorDigitalPropertyTypesNotFound',
  ErrorDocumentClientMustBeWorkspaceUser = 'ErrorDocumentClientMustBeWorkspaceUser',
  ErrorDocumentNotFound = 'ErrorDocumentNotFound',
  ErrorDocumentFileNotFound = 'ErrorDocumentFileNotFound',
  ErrorUrlIsAScannableDocument = 'ErrorUrlIsAScannableDocument',
  ErrorFileExceedsLimit = 'ErrorFileExceedsLimit',
  ErrorScannableDocumentNumberOfFilesExceedsLimit = 'ErrorScannableDocumentNumberOfFilesExceedsLimit',
  ErrorScannableDocumentFileIsRequired = 'ErrorScannableDocumentFileIsRequired',
  ErrorScannableDocumentUnsupportedFileExtension = 'ErrorScannableDocumentUnsupportedFileExtension',
  ErrorScannableDocumentDownloadUnsuccessful = 'ErrorScannableDocumentDownloadUnsuccessful',
  ErrorAuditedDocumentNotFound = 'ErrorAuditedDocumentNotFound',
  ErrorDuplicateRoleName = 'ErrorDuplicateRoleName',
  ErrorDuplicateTag = 'ErrorDuplicateTag',
  ErrorDuplicateWorkspaceRoleName = 'ErrorDuplicateWorkspaceRoleName',
  ErrorDuplicationCreatingDigitalPropertiesTypes = 'ErrorDuplicationCreatingDigitalPropertiesTypes',
  ErrorExceedScanUrlsSizeLimit = 'ErrorExceedScanUrlsSizeLimit',
  ErrorGettingCountriesList = 'ErrorGettingCountriesList',
  InvalidDigitalPropertyKey = 'InvalidDigitalPropertyKey',
  ErrorGettingUserScanOptions = 'ErrorGettingUserScanOptions',
  ErrorGettingUserWorkspace = 'ErrorGettingUserWorkspace',
  ErrorGettingUserWorkspaceRoles = 'ErrorGettingUserWorkspaceRoles',
  ErrorGettingWorkspace = 'ErrorGettingWorkspace',
  ErrorGettingWorkspaceRoles = 'ErrorGettingWorkspaceRoles',
  ErrorInvalidApiParameter = 'ErrorInvalidApiParameter',
  ErrorInvalidJSONAuditRuleData = 'ErrorInvalidJSONAuditRuleData',
  ErrorInvalidQueryParam = 'ErrorInvalidQueryParam',
  ErrorJobIndustryDBError = 'ErrorJobIndustryDBError',
  ErrorLoadingScanJobPage = 'ErrorLoadingScanJobPage',
  ErrorLastDigitalPropertyCannotBeArchived = 'ErrorLastDigitalPropertyCannotBeArchived',
  ErrorNonMonitoringScan = 'ErrorNonMonitoringScan',
  ErrorMissingAPIParameter = 'ErrorMissingAPIParameter',
  ErrorMissingAuditToolInDB = 'ErrorMissingAuditToolInDB',
  ErrorMissingReportBuilder = 'ErrorMissingReportBuilder',
  ErrorMissingConfirmEmailToken = 'ErrorMissingConfirmEmailToken',
  ErrorInvalidConfirmEmailToken = 'ErrorInvalidConfirmEmailToken',
  ErrorReservedSystemTagUsedWhereNotAllowed = 'ErrorReservedSystemTagUsedWhereNotAllowed',
  ErrorMultipleAccountSetUpsNotAllowed = `ErrorMultipleAccountSetUpsNotAllowed`,
  ErrorNotAllowedToModifyOtherUsersScanOptions = 'ErrorNotAllowedToModifyOtherUsersScanOptions',
  ErrorProcessingReportDetails = 'ErrorProcessingReportDetails',
  ErrorProcessingScanRequest = 'ErrorProcessingScanRequest',
  ErrorReportNotFound = 'ErrorReportNotFound',
  ErrorCannotRerunCIToolScan = 'ErrorCannotRerunCIToolScan',
  ErrorCompanyNotFound = 'ErrorCompanyNotFound',
  ErrorDecodedTokenUserIdInvalid = 'ErrorDecodedTokenUserIdInvalid',
  ErrorDigitalPropertyArchived = 'ErrorDigitalPropertyArchived',
  ErrorInvalidResetPasswordToken = 'ErrorInvalidResetPasswordToken',
  ErrorIncorrectToolOrVersion = 'ErrorIncorrectToolOrVersion',
  ErrorMissingScanAudit = 'ErrorMissingScanAudit',
  ErrorProjectNotFound = 'ErrorProjectNotFound',
  ErrorTaskNotFound = 'ErrorTaskNotFound',
  ErrorSomeTasksNotFound = 'ErrorSomeTasksNotFound',
  ErrorSomeTasksHaveUnallowedStatus = 'ErrorSomeTasksHaveUnallowedStatus',
  ErrorTaskIsLinked = 'ErrorTaskIsLinked',
  ErrorTaskAlreadyAssignedToSameUser = 'ErrorTaskAlreadyAssignedToSameUser',
  ErrorTaskAlreadyUnassigned = 'ErrorTaskAlreadyUnassigned',
  ErrorDuplicateIssueTrackingConnection = 'ErrorDuplicateIssueTrackingConnection',
  ErrorCannotGetJiraAuthorizeUrl = 'ErrorCannotGetJiraAuthorizeUrl',
  ErrorJiraAuthorizeUrlFailAuthentication = 'ErrorJiraAuthorizeUrlFailAuthentication',
  ErrorJiraInstanceUrlMismatch = 'ErrorJiraInstanceUrlMismatch',
  ErrorCannotCreateJiraIssue = 'ErrorCannotCreateJiraIssue',
  ErrorInvalidIssueTrackingConnectionInstance = 'ErrorInvalidIssueTrackingConnectionInstance',
  ErrorCannotVerifyJiraCode = 'ErrorCannotVerifyJiraCode',
  ErrorIssueTrackingConnectionNotFoundInDb = 'ErrorIssueTrackingConnectionNotFoundInDb',
  ErrorIntegrationInstanceIsNotAuthorized = 'ErrorIntegrationInstanceIsNotAuthorized',
  ErrorCannotGetIssueTrackingProjects = 'ErrorCannotGetIssueTrackingProjects',
  ErrorCannotGetIssueTrackingProject = 'ErrorCannotGetIssueTrackingProject',
  ErrorCannotGetJiraIssueTypes = 'ErrorCannotGetJiraIssueTypes',
  ErrorCannotGetIssueTrackingIssueStatuses = 'ErrorCannotGetIssueTrackingIssueStatuses',
  ErrorCannotGetIssueTrackingIssueTypeStatuses = 'ErrorCannotGetIssueTrackingIssueTypeStatuses',
  ErrorCannotGetAsanaIssueTypeStatuses = 'ErrorCannotGetAsanaIssueTypeStatuses',
  ErrorCannotGetAsanaIssueStatuses = 'ErrorCannotGetAsanaIssueStatuses',
  ErrorMissingJiraInstanceOrIssueTrackingProject = 'ErrorMissingJiraInstanceOrIssueTrackingProject',
  ErrorMissingPropertiesScanResult = 'ErrorMissingPropertiesScanResult',
  ErrorRetrieveScanDB = 'ErrorRetrieveScanDB',
  ErrorRoleInUse = 'ErrorRoleInUse',
  ErrorWorkspaceClosed = 'ErrorWorkspaceClosed',
  ErrorWorkspaceUserNotFound = 'ErrorWorkspaceUserNotFound',
  ScanOptionNotFound = 'ScanOptionNotFound',
  ErrorScanEmailReports = 'ErrorScanEmailReports',
  ErrorScanJobExecutionFailed = 'ErrorScanJobExecutionFailed',
  ErrorScanJobInvalidMimeType = 'ErrorScanJobInvalidMimeType',
  ErrorScanJobInvalidResponseCode = 'ErrorScanJobInvalidResponseCode',
  ErrorScanMissingAudit = 'ErrorScanMissingAudit',
  ErrorScanMissingContentType = 'ErrorScanMissingContentType',
  ErrorScanPageLimitTooHigh = 'ErrorScanPageLimitTooHigh',
  ErrorScanStatusCode = 'ErrorScanStatusCode',
  ErrorScanTimeout = 'ErrorScanTimeout',
  ErrorAutomatedUserFlowTimeout = 'ErrorAutomatedUserFlowTimeout',
  ErrorScanningJobNotFound = 'ErrorScanningJobNotFound',
  ErrorSystemTag = 'ErrorSystemTag',
  ErrorTagNotFound = 'ErrorTagNotFound',
  ErrorUnsupportedFileType = 'ErrorUnsupportedFileType',
  ErrorUpdatingUserPreferences = 'ErrorUpdatingUserPreferences',
  ErrorUpdateRole = 'ErrorUpdateRole',
  ErrorUpdatingUserProfile = 'ErrorUpdatingUserProfile',
  ErrorUrlNotSubscribed = 'ErrorUrlNotSubscribed',
  ErrorUserDocumentNotFound = 'ErrorUserDocumentNotFound',
  ErrorUserNotFound = 'ErrorUserNotFound',
  ErrorUserNotFoundRetrievingLastUsedWorkspace = 'ErrorUserNotFoundRetrievingLastUsedWorkspace',
  ErrorUserNotFoundRetrievingLastUsedProperty = 'ErrorUserNotFoundRetrievingLastUsedProperty',
  ErrorUserRoleNotFound = 'ErrorUserRoleNotFound',
  ErrorWorkspaceNotFound = 'ErrorWorkspaceNotFound',
  ErrorWorkspaceRoleInUse = 'ErrorWorkspaceRoleInUse',
  ErrorWorkspaceDuplicateName = 'ErrorWorkspaceDuplicateName',
  ExpectingString = 'ExpectingString',
  ExpectingValidURL = 'ExpectingValidURL',
  ExpectingOneOf = 'ExpectingOneOf',
  ExpectingManyOf = 'ExpectingManyOf',
  CurrentUserMustBeWorkspaceMember = 'CurrentUserMustBeWorkspaceMember',
  FailedToAuthenticateAccessToken = 'FailedToAuthenticateAccessToken',
  FailedToAuthenticateRefreshToken = 'FailedToAuthenticateRefreshToken',
  FilesWithRestrictedUrls = 'FilesWithRestrictedUrls',
  LogInIncorrectUsernameOrPassword = 'LogInIncorrectUsernameOrPassword',
  LogInAccountIsLocked = 'LogInAccountIsLocked',
  ImageValidationFailed = 'ImageValidationFailed',
  InvalidApiKey = 'InvalidApiKey',
  InvalidAuditResults = 'InvalidAuditResults',
  InvalidCurrentPassword = 'InvalidCurrentPassword',
  InvalidIdValue = 'InvalidIdValue',
  InvalidReportFormat = 'InvalidReportFormat',
  InvalidRequestStructure = 'InvalidRequestStructure',
  InvalidRequestBody = 'InvalidRequestBody',
  InvalidRequestBodyKeys = 'InvalidRequestBodyKeys',
  InvalidRuleId = 'InvalidRuleId',
  InvalidRulesForTool = 'InvalidRulesForTool',
  InvalidScanningFromDate = 'InvalidScanningFromDate',
  InvalidScanningToDate = 'InvalidScanningToDate',
  InvalidScanningType = 'InvalidScanningType',
  InvalidUrl = 'InvalidUrl',
  InvalidUrls = 'InvalidUrls',
  LocaleCreateInvalidRequest = 'LocaleCreateInvalidRequest',
  LogInMissingLogin = 'LogInMissingLogin',
  LogInMissingPassword = 'LogInMissingPassword',
  MalformedScanningURL = 'MalformedScanningURL',
  ManualExcelAuditUploadFailed = 'ManualExcelAuditUploadFailed',
  ManualAuditDuplicatedIssueId = 'ManualAuditDuplicatedIssueId',
  ManualAuditInvalidConformanceTarget = 'ManualAuditInvalidConformanceTarget',
  ManualEvaluationScopeNotFound = 'ManualEvaluationScopeNotFound',
  ManualEvaluationInvalidStatusForConformanceScoreCalculation = 'ManualEvaluationInvalidStatusForConformanceScoreCalculation',
  ManualAuditInvalidIssueIdType = 'ManualAuditInvalidIssueIdType',
  ManualAuditMissingCustomRule = 'ManualAuditMissingCustomRule',
  ManualAuditMissingFile = 'ManualAuditMissingFile',
  ManualAuditNotFound = 'ManualAuditNotFound',
  ManualAuditIssueNotFound = 'ManualAuditIssueNotFound',
  ManualAuditIssueAttachmentNotFound = 'ManualAuditIssueAttachmentNotFound',
  OnlyManualAuditScansCanBeArchived = 'OnlyManualAuditScansCanBeArchived',
  MissingColumn = 'MissingColumn',
  MissingEmailsInSendEmail = 'MissingEmailsInSendEmail',
  MissingFilesOrTextWithCustomUrls = 'MissingFilesOrTextWithCustomUrls',
  MissingMandatoryValue = 'MissingMandatoryValue',
  MissingScanningJobId = 'MissingScanningJobId',
  MissingScanningURL = 'MissingScanningURL',
  MissingSheet = 'MissingSheet',
  NoAccessRefreshTokenProvided = 'NoAccessRefreshTokenProvided',
  NoApiTokenProvided = 'NoApiTokenProvided',
  ScimApiTokenRequired = 'ScimApiTokenRequired',
  NoResultForAuditJob = 'NoResultForAuditJob',
  NoteDoesNotExists = 'NoteDoesNotExists',
  NoAccessToDigitalProperty = 'NoAccessToDigitalProperty',
  NoAccessToWorkspace = 'NoAccessToWorkspace',
  ErrorUserHasNoAvailableWorkspaces = 'ErrorUserHasNoAvailableWorkspaces',
  ReportErrorNoAuditDefinition = 'ReportErrorNoAuditDefinition',
  ReportErrorScanAuditNotDefined = 'ReportErrorScanAuditNotDefined',
  ReportErrorReportAuditTypeIsNotDefined = 'ReportErrorReportAuditTypeIsNotDefined',
  RequestInvalidRole = 'RequestInvalidRole',
  RequiredFieldsEmptyOrNull = 'RequiredFieldsEmptyOrNull',
  RequiredFields = 'RequiredFields',
  ResourceDoesNotExists = 'ResourceDoesNotExists',
  RuleIdNotFound = 'RuleIdNotFound',
  RuleNotFound = 'RuleNotFound',
  ScanAuthenticationInvalidPasswordField = 'ScanAuthenticationInvalidPasswordField',
  ScanAuthenticationInvalidSubmitField = 'ScanAuthenticationInvalidSubmitField',
  ScanAuthenticationInvalidUsernameSubmitField = 'ScanAuthenticationInvalidUsernameSubmitField',
  ScanAuthenticationInvalidSuccessField = 'ScanAuthenticationInvalidSuccessField',
  ScanAuthenticationInvalidUsernameField = 'ScanAuthenticationInvalidUsernameField',
  ScanDoesntBelongToRequestedDigitalProperty = 'ScanDoesntBelongToRequestedDigitalProperty',
  ScanJobAborted = 'ScanJobAborted',
  ScanMissingSecureWebAuthenticationData = 'ScanMissingSecureWebAuthenticationData',
  ScanMonthlyScheduleInvalidDay = 'ScanMonthlyScheduleInvalidDay',
  CannotCreateScheduleScanEmptyDates = 'CannotCreateScheduleScanEmptyDates',
  ScanNotFound = 'ScanNotFound',
  AutomatedUserFlowActionFailed = 'AutomatedUserFlowActionFailed',
  ShortLinkNotFound = 'ShortLinkNotFound',
  ScansNotFound = 'ScansNotFound',
  ScanTagMissing = 'ScanTagMissing',
  ScansForMonitoredDigitalPropertyDontExist = 'ScansForMonitoredDigitalPropertyDontExist',
  ScanStopDateMoreThanStartDate = 'ScanStopDateMoreThanStartDate',
  ScanSummariesForMonitoredDigitalPropertyDontExist = 'ScanSummariesForMonitoredDigitalPropertyDontExist',
  ScanSummaryNotFound = 'ScanSummaryNotFound',
  ScanWeeklyScheduleInvalidDay = 'ScanWeeklyScheduleInvalidDay',
  ScanYearlyScheduleInvalidDay = 'ScanYearlyScheduleInvalidDay',
  ScanYearlyScheduleInvalidMonth = 'ScanYearlyScheduleInvalidMonth',
  ServiceUnavailable = 'ServiceUnavailable',
  SignUpMissingLogin = 'SignUpMissingLogin',
  SignUpMissingPassword = 'SignUpMissingPassword',
  TranslationConfigNotFound = 'TranslationConfigNotFound',
  TranslationImportInvalidFile = 'TranslationImportInvalidFile',
  TranslationKeyEmptyOrNull = 'TranslationKeyEmptyOrNull',
  TranslationLangConfigAlreadyExists = 'TranslationLangConfigAlreadyExists',
  TranslationLangConfigNotFound = 'TranslationLangConfigNotFound',
  TranslationRemoveDefaultLanguage = 'TranslationRemoveDefaultLanguage',
  TranslationRemoveLastLanguage = 'TranslationRemoveLastLanguage',
  TranslationValueEmpty = 'TranslationValueEmpty',
  TrainingVideoNotFound = 'TrainingVideoNotFound',
  TrainingVideoDomainNotAllowed = 'TrainingVideoDomainNotAllowed',
  UnableGettingUserWorkspaceRoles = 'UnableGettingUserWorkspaceRoles',
  UnexpectedFormatColumns = 'UnexpectedFormatColumns',
  UnableSignupUnauthenticatedUser = 'UnableSignupUnauthenticatedUser',
  UnableToSaveAccessToken = 'UnableToSaveAccessToken',
  UnableToSaveUser = 'UnableToSaveUser',
  UnauthorizedDigitalPropertyId = 'UnauthorizedDigitalPropertyId',
  UnknownError = 'UnknownError',
  UrlsWithRestrictedUrls = 'UrlsWithRestrictedUrls',
  UserAlreadyExists = 'UserAlreadyExists',
  UserAlreadyExistsInWorkspace = 'UserAlreadyExistsInWorkspace',
  UserEmailAlreadyExists = 'UserEmailAlreadyExists',
  UserDisabled = 'UserDisabled',
  UserIdNotDefined = 'UserIdNotDefined',
  UserNotAuthorizedOnWorkspace = 'UserNotAuthorizedOnWorkspace',
  UserNotAuthorized = 'UserNotAuthorized',
  AuthUserDoesNotExist = 'AuthUserDoesNotExist',
  UserRoleNotDefined = 'UserRoleNotDefined',
  ValidationFailed = 'ValidationFailed',
  PageSummaryNotFound = 'PageSummaryNotFound',
  JobTitleNotFound = 'JobTitleNotFound',
  UserNotActive = 'UserNotActive',
  UserInvalid = 'UserInvalid',
  AccountDeactivated = 'AccountDeactivated',
  InvalidPasswordPattern = 'InvalidPasswordPattern',
  DigitalPropertyMonitoringInvalidHeader = 'DigitalPropertyMonitoringInvalidHeader',
  DigitalPropertyMonitoringInvalidEndDate = 'DigitalPropertyMonitoringInvalidEndDate',
  DigitalPropertyMonitoringInvalidStartDate = 'DigitalPropertyMonitoringInvalidStartDate',
  RuleAuditHistoryTypeNotSupported = 'RuleAuditHistoryTypeNotSupported',
  DigitalPropertyInvalidPagesLimit = 'DigitalPropertyInvalidPagesLimit',
  TranslationNotFound = 'TranslationNotFound',
  FieldTooLong = 'FieldTooLong',
  FieldTooShort = 'FieldTooShort',
  NoMatchingTaskFound = 'NoMatchingTaskFound',
  TaskStateTransitionNotPossible = 'TaskStateTransitionNotPossible',
  UnauthorizedCommentDeletion = 'UnauthorizedCommentDeletion',
  ErrorCannotGetJiraIssue = 'ErrorCannotGetJiraIssue',
  ErrorFlawNotFound = 'ErrorFlawNotFound',
  ErrorFlawPageNotFound = 'ErrorFlawPageNotFound',
  LogInUserNotFound = 'LogInUserNotFound',
  SignUpInvalidEmail = 'SignUpInvalidEmail',
  LogInUserNotActive = 'LogInUserNotActive',
  SignUpInvalidPassword = 'SignUpInvalidPassword',
  ErrorFlawInvalidUserStatusChange = 'ErrorFlawInvalidUserStatusChange',
  ErrorFlawInvalidDigitalProperty = 'ErrorFlawInvalidDigitalProperty',
  ErrorFlawInvalidScan = 'ErrorFlawInvalidScan',
  ErrorFlawInvalidFinding = 'ErrorFlawInvalidFinding',
  ErrorFlawInvalidDocument = 'ErrorFlawInvalidDocument',
  ErrorFlawAttachTaskInvalidStatus = 'ErrorFlawAttachTaskInvalidStatus',
  ErrorFlawAttachTaskAlreadyLinked = 'ErrorFlawAttachTaskAlreadyLinked',
  ScanMissingBasicAuthData = 'ScanMissingBasicAuthData',
  ErrorManualFindingStatusUpdateDismissedLinkedTaskBadRequest = 'ErrorManualFindingStatusUpdateDismissedLinkedTaskBadRequest',
  ErrorManualFindingStatusUpdateDismissedReasonRequiredBadRequest = 'ErrorManualFindingStatusUpdateDismissedReasonRequiredBadRequest',
  ErrorCreateTaskFromDismissedManualFindingBadRequest = 'ErrorCreateTaskFromDismissedManualFindingBadRequest',
  ErrorCreateTaskWithoutFindings = 'ErrorCreateTaskWithoutFindings',
  ErrorCreateTaskWithDuplicatedFinding = 'ErrorCreateTaskWithDuplicatedFinding',
  ErrorNoScanResultsAvailable = 'ErrorNoScanResultsAvailable',
  AuditRulesInvalidFilter = 'AuditRulesInvalidFilter',
  ActiveScansExceedLimit = 'ActiveScansExceedLimit',
  RequestsCountExceedLimit = 'RequestsCountExceedLimit',
  WorkspaceSettingActiveScansLimit = 'WorkspaceSettingActiveScansLimit',
  NotFound = 'NotFound',
  ErrorCannotDeleteProject = 'ErrorCannotDeleteProject',
  ErrorCannotArchiveProject = 'ErrorCannotArchiveProject',
  MissingAuditToolByNameAndVersion = 'MissingAuditToolByNameAndVersion',
  RescanMaxLimitExceeded = 'RescanMaxLimitExceeded',
  MissingAuditToolForScan = 'MissingAuditToolForScan',
  ErrorReadXLSXFile = 'ErrorReadXLSXFile',
  DigitalPropertyDoesNotBelongToWorkspace = 'DigitalPropertyDoesNotBelongToWorkspace',
  ErrorUnknownSeverity = 'ErrorUnknownSeverity',
  WorkspaceDoesNotBelongToTenant = 'WorkspaceDoesNotBelongToTenant',
  TenantNotFound = 'TenantNotFound',
  NoAccessToManualFindingComment = 'NoAccessToManualFindingComment',
  ManualFindingCommentDoesNotBelongToManualAudit = 'ManualFindingCommentDoesNotBelongToManualAudit',
  NoAccessToTenant = 'NoAccessToTenant',
  NoStaffAccessInSsoTenant = 'NoStaffAccessInSsoTenant',
  InvalidMetricsUpdate = 'InvalidMetricsUpdate',
  ErrorTenantInvalidSubdomain = 'ErrorTenantInvalidSubdomain',
  ErrorTenantDuplicateSubdomain = 'ErrorTenantDuplicateSubdomain',
  UserDoesNotBelongToTenant = 'UserDoesNotBelongToTenant',
  ErrorDuplicateResolvedUrl = 'ErrorDuplicateResolvedUrl',
  ErrorRequestForbiddenInTenantless = 'ErrorRequestForbiddenInTenantless',
  ErrorRequestForbiddenOutsideTenantless = 'ErrorRequestForbiddenOutsideTenantless',
  UrlDoesntBelongToDigitalProperty = 'UrlDoesntBelongToDigitalProperty',
  ErrorInvalidSitemapFormat = 'ErrorInvalidSitemapFormat',
  ErrorInvalidSitemapUrl = 'ErrorInvalidSitemapUrl',
  ErrorActivityLogsDoNotBelongToUserWorkspace = 'ErrorActivityLogsDoNotBelongToUserWorkspace',
  ErrorActivityLogsInaccessibleToUser = 'ErrorActivityLogsInaccessibleToUser',
  ErrorDocumentDigitalPropertyMustBeInWorkspace = 'ErrorDocumentDigitalPropertyMustBeInWorkspace',
  ErrorDocumentDigitalPropertiesShouldNotBeEmpty = 'ErrorDocumentDigitalPropertiesShouldNotBeEmpty',
  ErrorDocumentTitleNotBeEmpty = 'ErrorDocumentTitleNotBeEmpty',
  ErrorDocumentDeliveryDateInvalidFormat = 'ErrorDocumentDeliveryDateInvalidFormat',
  ErrorUrlHasEmptyContentType = 'ErrorUrlHasEmptyContentType',
  ErrorInvalidAdditionalEmails = 'ErrorInvalidAdditionalEmails',
  OnlyTenantlessAccess = 'OnlyTenantlessAccess',
  ErrorLoginToTenantlessForbidden = 'ErrorLoginToTenantlessForbidden',
  ErrorLoginToTenantForbidden = 'ErrorLoginToTenantForbidden',
  ErrorCannotAccessFile = 'ErrorCannotAccessFile',
  InternalServerError = 'InternalServerError',
  ExternalAuthUserNotFound = 'ExternalAuthUserNotFound',
  ExternalAuthVerificationError = 'ExternalAuthVerificationError',
  ErrorCannotCreateAsanaConnection = 'ErrorCannotCreateAsanaConnection',
  ErrorCannotCreateAzureConnection = 'ErrorCannotCreateAzureConnection',
  ErrorInvalidAzureConnectionInstance = 'ErrorInvalidAzureConnectionInstance',
  ErrorInvalidAzureConnectionPAT = 'ErrorInvalidAzureConnectionPAT',
  ErrorDuplicateAzureConnection = 'ErrorDuplicateAzureConnection',
  ErrorInvalidAsanaConnectionInstance = 'ErrorInvalidAsanaConnectionInstance',
  ErrorInvalidAsanaConnectionPAT = 'ErrorInvalidAsanaConnectionPAT',
  ErrorDuplicateAsanaConnection = 'ErrorDuplicateAsanaConnection',
  ErrorCannotGetAzureIssueTypes = 'ErrorCannotGetAzureIssueTypes',
  ErrorCannotGetAzureIssueTypeStatuses = 'ErrorCannotGetAzureIssueTypeStatuses',
  ErrorCannotGetAzureIssueStatuses = 'ErrorCannotGetAzureIssueStatuses',
  ErrorInvalidIPAddress = 'ErrorInvalidIPAddress',
  ErrorDuplicateUserFlowTemplateName = 'ErrorDuplicateUserFlowTemplateName',
  ErrorEmptyUserFlowTemplateName = 'ErrorEmptyUserFlowTemplateName',
  ErrorMinimumUserFlowScanPointsRequired = 'ErrorMinimumUserFlowScanPointsRequired',
  ErrorDuplicatePageName = 'ErrorDuplicatePageName',
  ErrorPageScanNullResult = 'ErrorPageScanNullResult',
  PageNotFound = 'PageNotFound',
  PageAlreadyScanned = 'PageAlreadyScanned',
  PageDoesNotBelongToDigitalProperty = 'PageDoesNotBelongToDigitalProperty',
  NoAccessToPage = 'NoAccessToPage',
  UserFlowTemplateNotFound = 'UserFlowTemplateNotFound',
  NoAccessToUserFlowTemplate = 'NoAccessToUserFlowTemplate',
  UserFlowTemplateDoesNotBelongToDigitalProperty = 'UserFlowTemplateDoesNotBelongToDigitalProperty',
  UserFlowTemplateNotFoundByName = 'UserFlowTemplateNotFoundByName',
  ScannableDocumentNotCorrectStatus = 'ScannableDocumentNotCorrectStatus',
  ScannableDocumentNotFound = 'ScannableDocumentNotFound',
  ScannableDocumentNotScanned = 'ScannableDocumentNotScanned',
  ErrorScannableDocumentRuleNameNotFound = 'ErrorScannableDocumentRuleNameNotFound',
  ErrorScannableDocumentRuleIdNotFound = 'ErrorScannableDocumentRuleIdNotFound',
  ErrorScannableDocumentPageIdNotFound = 'ErrorScannableDocumentPageIdNotFound',
  ErrorInvalidFile = 'ErrorInvalidFile',
  ErrorInvalidDocumentScanResultStatus = 'ErrorInvalidDocumentScanResultStatus',
  ErrorMissingScannableDocumentPage = 'ErrorMissingScannableDocumentPage',
  ErrorInvalidScannableDocumentMetadata = 'ErrorInvalidScannableDocumentMetadata',
  ScreenshotResultsNotFound = 'ScreenshotResultsNotFound',
  UserEmailVerificationTokenInvalid = 'UserEmailVerificationTokenInvalid',
  ProjectDoesNotBelongToWorkspace = 'ProjectDoesNotBelongToWorkspace',
  ErrorCannotGetJiraRequiredFields = 'ErrorCannotGetJiraRequiredFields',
  ErrorCannotGetAzureRequiredFields = 'ErrorCannotGetAzureRequiredFields',
  ErrorUnsupportedIssueTrackingIntegrationType = 'ErrorUnsupportedIssueTrackingIntegrationType',
  ErrorInconsistentIssueTrackingStatusMapping = 'ErrorInconsistentIssueTrackingStatusMapping',
  ErrorInvalidStatusMapping = 'ErrorInvalidStatusMapping',
  ErrorCannotGetJiraProjectUsers = 'ErrorCannotGetJiraProjectUsers',
  CrawlPagesRequestNotFound = 'CrawlPagesRequestNotFound',
  ErrorCannotGetJiraLabels = 'ErrorCannotGetJiraLabels',
  ErrorUploadingFile = 'ErrorUploadingFile',
  UnexpectedResponseFromIssueTracking = 'UnexpectedResponseFromIssueTracking',
  NoAccessToDocument = 'NoAccessToDocument',
  NotImplemented = 'NotImplemented',
  DocumentDoesNotBelongToWorkspace = 'DocumentDoesNotBelongToWorkspace',
  InvalidScanningTypeSourceForPushScan = 'InvalidScanningTypeSourceForPushScan',
  InvalidMobilePlatformDetailsForPushScan = 'InvalidMobilePlatformDetailsForPushScan',
  RequiredMobilePlatformDetailsForPushScan = 'RequiredMobilePlatformDetailsForPushScan',
  ErrorUnsupportedDocumentScanner = 'ErrorUnsupportedDocumentScanner',
  InvalidScanTagForPushScan = 'InvalidScanTagForPushScan',
  ErrorMethodNotAvailableForJira = 'ErrorMethodNotAvailableForJira',
  ErrorMethodNotAvailableForAsana = 'ErrorMethodNotAvailableForAsana',
  ErrorMethodNotAvailableForAzure = 'ErrorMethodNotAvailableForAzure',
  CannotMigrateWorkspaceWithNoDPToTenant = 'CannotMigrateWorkspaceWithNoDPToTenant',
  ErrorInvalidDesignRuleDataJSON = 'ErrorInvalidDesignRuleDataJSON',
  ErrorInvalidDesignRuleId = 'ErrorInvalidDesignRuleId',
  ErrorInvalidDesignRuleField = 'ErrorInvalidDesignRuleField',
  ErrorScanPagesExceededDownloadLimit = 'ErrorScanPagesExceededDownloadLimit',
  ErrorMatchComponentNameNotUnique = 'ErrorMatchComponentNameNotUnique',
  ErrorMatchComponentCssSelectorNotUnique = 'ErrorMatchComponentCssSelectorNotUnique',
  MatchComponentNotFound = 'MatchComponentNotFound',
  MatchComponentNotFoundByName = 'MatchComponentNotFoundByName',
  FieldNotMatchingRegex = 'FieldNotMatchingRegex',
  FieldMatchingRegex = 'FieldMatchingRegex',
  FieldContainsInvalidCharacters = 'FieldContainsInvalidCharacters',
  FieldMustBeString = 'FieldMustBeString',
  ErrorNumberOfFilesExceedsLimit = 'ErrorNumberOfFilesExceedsLimit',
  ErrorNumberOfNewFilesExceedsLimit = 'ErrorNumberOfNewFilesExceedsLimit',
  InvalidBoundingBox = 'InvalidBoundingBox',
  InvalidIssueType = 'InvalidIssueType',
  InvalidFindingStatus = 'InvalidFindingStatus',
  ErrorDesignReviewProjectNotFound = 'ErrorDesignReviewProjectNotFound',
  ErrorDesignReviewProjectNotCompleted = 'ErrorDesignReviewProjectNotCompleted',
  ErrorDesignReviewPageNotFound = 'ErrorDesignReviewPageNotFound',
  ErrorDuplicateDesignPageName = 'ErrorDuplicateDesignPageName',
  ErrorDesignReviewCannotModifyArchivedProject = 'ErrorDesignReviewCannotModifyArchivedProject',
  ErrorDesignReviewCannotModifyCompletedProject = 'ErrorDesignReviewCannotModifyCompletedProject',
  ErrorDesignReviewFindingsReportNotYetGenerated = 'ErrorDesignReviewFindingsReportNotYetGenerated',
  ErrorDesignReviewFindingNotFound = 'ErrorDesignReviewFindingNotFound',
  ErrorDesignReviewFindingCommentNotFound = 'ErrorDesignReviewFindingCommentNotFound',
  ErrorDesignReviewNumberOfFindingsExceedsLimit = 'ErrorDesignReviewNumberOfFindingsExceedsLimit',
  ErrorDesignReviewNoAccessToProject = 'ErrorDesignReviewNoAccessToProject',
  ErrorDesignReviewNoAccessToPage = 'ErrorDesignReviewNoAccessToPage',
  ErrorDesignReviewNoAccessToFinding = 'ErrorDesignReviewNoAccessToFinding',
  ErrorIncorrectOrExpiredMfaToken = 'ErrorIncorrectOrExpiredMfaToken',
  ErrorCannotEnableMfaOnTenantWithSso = 'ErrorCannotEnableMfaOnTenantWithSso',
  ErrorMfaAlreadyEnabled = 'ErrorMfaAlreadyEnabled',
  ErrorGenerateMfaSecretForUserFailed = 'ErrorGenerateMfaSecretForUserFailed',
  ErrorVerifyMfaTokenFailed = 'ErrorVerifyMfaTokenFailed',
  ErrorInvalidInputForDecryption = 'ErrorInvalidInputForDecryption',
  EncryptionError = 'EncryptionError',
  DecryptionError = 'DecryptionError',
  PushSaveResultsScanCompleted = 'PushSaveResultsScanCompleted',
  PushShouldHaveCompleteRequestBody = 'PushShouldHaveCompleteRequestBody',
  ErrorInvalidResourceId = 'ErrorInvalidResourceId',
  FieldBelowMinimum = 'FieldBelowMinimum',
  FieldAboveMaximum = 'FieldAboveMaximum',
  ErrorOnlyOneRemediatedFileUploadAllowed = 'ErrorOnlyOneRemediatedFileUploadAllowed',
  ErrorMaxNumberOfPdfPagesTestedExceeded = 'ErrorMaxNumberOfPdfPagesTestedExceeded',
  ErrorUpdatingPdfPagesTestedCount = 'ErrorUpdatingPdfPagesTestedCount',
  ErrorScannableDocumentAlreadyHasRemediatedDocument = 'ErrorScannableDocumentAlreadyHasRemediatedDocument',
  ErrorScannableDocumentNotAwaitingRemediation = 'ErrorScannableDocumentNotAwaitingRemediation',
  DuplicateScanPointNotAllowed = 'DuplicateScanPointNotAllowed',
  CssSelectorInvalid = 'CssSelectorInvalid',
  SecurityRoleForPackageNotFound = 'SecurityRoleForPackageNotFound',
  ErrorCreateSecurityGroupWithoutName = 'ErrorCreateSecurityGroupWithoutName',
  ErrorCreateSecurityGroupWithoutRoles = 'ErrorCreateSecurityGroupWithoutRoles',
  ErrorCreateSecurityGroupWithoutEntities = 'ErrorCreateSecurityGroupWithoutEntities',
  ErrorCreateFromSecurityGroupTemplatesEntityLevelMismatch = 'ErrorCreateFromSecurityGroupTemplatesEntityLevelMismatch',
  ErrorSecurityGroupNotFound = 'ErrorSecurityGroupNotFound',
  ErrorSecurityGroupTemplateNotFound = 'ErrorSecurityGroupTemplateNotFound',
  ErrorPredefinedSecurityGroupNotFound = 'ErrorPredefinedSecurityGroupNotFound',
  InvalidDesignEvaluationFindingField = 'InvalidDesignEvaluationFindingField',
  ErrorScannableDocumentNumberOfPagesNotFound = 'ErrorScannableDocumentNumberOfPagesNotFound',
  ErrorExcludeUrlOneToOneCorrespondenceNotPresent = 'ErrorExcludeUrlOneToOneCorrespondenceNotPresent',
  InvalidHighlight = 'InvalidHighlight',
  AutomatedUserFlowNotFound = 'AutomatedUserFlowNotFound',
  AutomatedUserFlowNotValid = 'AutomatedUserFlowNotValid',
  TenantMissingAcademyIntegration = 'TenantMissingAcademyIntegration',
  TenantUserMissingAcademyIntegration = 'TenantUserMissingAcademyIntegration',
  TenantUserMissingAcademyTenantIntegration = 'TenantUserMissingAcademyTenantIntegration',
  AcademyNotFoundError = 'AcademyNotFoundError',
  AcademyAlreadyAssignedToAnotherTenant = 'AcademyAlreadyAssignedToAnotherTenant',
  AcademyWithGivenNameAlreadyExists = 'AcademyWithGivenNameAlreadyExists',
  AcademyPanoramaWithGivenSkuNotFound = 'AcademyPanoramaWithGivenSkuNotFound',
  AcademySublicenseWithGivenAttributesNotFound = 'AcademySublicenseWithGivenAttributesNotFound',
  AcademyContentTagNotFound = 'AcademyContentTagNotFound',
  AcademyDefaultContentTagNotFound = 'AcademyDefaultContentTagNotFound',
  ErrorDesignReviewHighlightNotFound = 'ErrorDesignReviewHighlightNotFound',
  ErrorDesignReviewNoAccessToHighlight = 'ErrorDesignReviewNoAccessToHighlight',
  DesignReviewFindingRevisionNotMatch = 'DesignReviewFindingRevisionNotMatch',
  ScannableDocumentInvalid = 'ScannableDocumentInvalid',
  DuplicatedDesignRuleId = 'DuplicatedDesignRuleId',
  IncorrectValue = 'IncorrectValue',
  InvalidAMPJSONUpload = 'InvalidAMPJSONUpload',
  PackageNotFound = 'PackageNotFound',
  PackageInstanceNotFound = 'PackageInstanceNotFound',
  TenantAlreadyHasPackageInstance = 'TenantAlreadyHasPackageInstance',
  ThisIsNotAValidImage = 'ThisIsNotAValidImage',
  FieldMustBeUnixTimestamp = 'FieldMustBeUnixTimestamp',
  SalesforceAccountNotFound = 'SalesforceAccountNotFound',
  DuplicateTenantSalesforceAccountId = 'DuplicateTenantSalesforceAccountId',
  FeatureFlagUnauthorized = 'FeatureFlagUnauthorized',
  InvalidStaffSecurityGroupName = 'InvalidStaffSecurityGroupName',
  DigitalPropertyUsageLimitReached = 'DigitalPropertyUsageLimitReached',
  LegacyEaPackageUnavailable = 'LegacyEaPackageUnavailable',
  LegacyEaPackageUnavailableForCreate = 'LegacyEaPackageUnavailableForCreate',
  ScanIdsLengthDifferentFromToolIdsLength = 'ScanIdsLengthDifferentFromToolIdsLength',
  SystemUserNotAllowedForOperation = 'SystemUserNotAllowedForOperation',
  UserEmailDoesNotExistInThePlatform = 'UserEmailDoesNotExistInThePlatform',
  ErrorInvalidWebhookEntities = 'ErrorInvalidWebhookEntities',
  AmpServiceUserNotFound = 'AmpServiceUserNotFound',
  NewPasswordCannotBeUsedAgain = 'NewPasswordCannotBeUsedAgain',
  PasswordIsTooOld = 'PasswordIsTooOld',
  ExternalIdExceedsMaximumLength = 'ExternalIdExceedsMaximumLength',
  ExternalIdRequestDuplicate = 'ExternalIdRequestDuplicate',
  ExternalIdAlreadyExists = 'ExternalIdAlreadyExists',
  ZendeskIntegrationError = 'ZendeskIntegrationError',
  MissingScreenshotAltText = 'MissingScreenshotAltText',
  ManualFindingExternalIdNotFound = 'ManualFindingExternalIdNotFound',
  UpdateFindingEmptyFields = 'UpdateFindingEmptyFields',
  StaffGroupsNotFound = 'StaffGroupsNotFound',
  ErrorEnvironmentNameNotUnique = 'ErrorEnvironmentNameNotUnique',
  ErrorEnvironmentURLNotUnique = 'ErrorEnvironmentURLNotUnique',
  ErrorEnvironmentInvalidURLDomain = 'ErrorEnvironmentInvalidURLDomain',
  ExternalIdRequired = 'ExternalIdRequired',
  ErrorContinuumProjectIdInvalid = 'ErrorContinuumProjectIdInvalid',
  FileUploadRequired = 'FileUploadRequired',
  InvalidUpdateEmailsFileFormat = 'InvalidUpdateEmailsFileFormat',
  DuplicateEmailsInUpdateEmailsFile = 'DuplicateEmailsInUpdateEmailsFile',
  UnequalLengthInUpdateEmailsFile = 'UnequalLengthInUpdateEmailsFile',
  EmailInBothTargetAndSourceInUpdateEmailsFile = 'EmailInBothTargetAndSourceInUpdateEmailsFile',
  EmptyUpdateEmailsFile = 'EmptyUpdateEmailsFile',
  MissingScope = 'MissingScope',
  TestGuideNotFound = 'TestGuideNotFound',
  ErrorCannotSendAiChatMessage = 'ErrorCannotSendAiChatMessage',
  ErrorAiChatMessageNotFound = 'ErrorAiChatMessageNotFound',
  AiDataSummaryNotFound = 'AiDataSummaryNotFound',
  ErrorGeneratingAiDataSummary = 'ErrorGeneratingAiDataSummary',
  DigitalPropertyMetricsInvalidScheduledAt = 'DigitalPropertyMetricsInvalidScheduledAt',
  IllegalTestGuideModification = 'IllegalTestGuideModification',
  IllegalAddingTestGuide = 'IllegalAddingTestGuide',
  ErrorMissingIssueId = 'ErrorMissingIssueId',
  ErrorIssueIdAlreadyLinked = 'ErrorIssueIdAlreadyLinked',
  ErrorCannotFindJiraIssue = 'ErrorCannotFindJiraIssue',
  ErrorCannotCreateWorkspaceDefaultDP = 'ErrorCannotCreateWorkspaceDefaultDP',
  ErrorDigitalPropertyDetailsRequired = 'ErrorDigitalPropertyDetailsRequired',
  CloudsmithIntegrationError = 'CloudsmithIntegrationError',
  ErrorIssueProjectMismatch = 'ErrorIssueProjectMismatch',
  ErrorIssueLinkedToOtherProject = 'ErrorIssueLinkedToOtherProject',
  ErrorRuleLibraryForCodeNotFound = 'ErrorRuleLibraryForCodeNotFound',
  InvalidUserRoleId = 'InvalidUserRoleId',
  CustomRuleNotFound = 'CustomRuleNotFound',
  ErrorCustomAuditRuleIdNotUnique = 'ErrorCustomAuditRuleIdNotUnique',
  InvalidWorkbook = 'InvalidWorkbook',
  TaskNotFound = 'TaskNotFound',
  ErrorCannotUpdateJiraWebhook = 'ErrorCannotUpdateJiraWebhook',
  ErrorIssueKeyDuplicated = 'ErrorIssueKeyDuplicated',
  ErrorValidationIssueLinkRequestFields = 'ErrorValidationIssueLinkRequestFields',
  ScimDisabled = 'ScimDisabled',
  ErrorCannotGetAsanaConfigurableFields = 'ErrorCannotGetAsanaConfigurableFields',
  ErrorCannotGetAsanaProjectUsers = 'ErrorCannotGetAsanaProjectUsers',
  UserNotScim = 'UserNotScim',
  UserNotUnassignedScim = 'UserNotUnassignedScim',
  ScimPatchOperationValueIsRequired = 'ScimPatchOperationValueIsRequired',
  ScimPatchOperationValueTypeInvalid = 'ScimPatchOperationValueTypeInvalid',
  ManualRequestNotFound = 'ManualRequestNotFound',
  StaffUserCannotBeUpdated = 'StaffUserCannotBeUpdated',
  StaffUserCannotBeRetrieved = 'StaffUserCannotBeRetrieved',
  ErrorFetchingFile = 'ErrorFetchingFile',
  AutomatedUserFlowUsageLimitReached = 'AutomatedUserFlowUsageLimitReached',
  PageEvaluationTimeout = 'PageEvaluationTimeout',
  PackageAddonNotFound = 'PackageAddonNotFound',
  UserWayAPICallError = 'UserWayAPICallError',
  DigitalPropertyMissingUrl = 'DigitalPropertyMissingUrl',
  UserWaySiteMaxExceeded = 'UserWaySiteMaxExceeded',
  ErrorDigitalPropertyNotWebTyped = 'ErrorDigitalPropertyNotWebTyped',
  CannotModifyEvaluation = 'CannotModifyEvaluation',
  UserWayHostNameDuplicated = 'UserWayHostNameDuplicated',
}
