import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET https://{organizationDomain}/api/v1/healthcheck/ping
  public ping(organizationDomain: string): Observable<void> {
    const url: string = `https://${organizationDomain}${this.restBuilder.create().healthcheck().ping().getApiUrl()}`;

    return this.httpClient.get<void>(url);
  }
}
