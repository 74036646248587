export enum validationError {
  invalidFileType = 'invalidFileType',
  invalidFileTypes = 'invalidFileTypes',
  attachmentQuantityExceedsLimit = 'attachmentQuantityExceedsLimit',
  attachmentSizeExceedsLimit = 'attachmentSizeExceedsLimit',
  digitalPropertyKey = 'digitalPropertyKey',
  digitalPropertyFieldWorkspaceDuplicate = 'digitalPropertyFieldWorkspaceDuplicate',
  masterLibraryRuleIdDuplicate = 'masterLibraryRuleIdDuplicate',
  userWayWebsiteDuplicate = 'userWayWebsiteDuplicate',
  notMatchDomain = 'notMatchDomain',
  urlInvalid = 'urlInvalid',
  in = 'in',
  maxlength = 'maxlength',
  datesValidator = 'datesValidator',
  charactersOnly = 'charactersOnly',
  invalidJSON = 'invalidJSON',
  invalidSubdomain = 'invalidSubdomain',
  invalidDate = 'invalidDate',
  minDate = 'minDate',
  maxDate = 'maxDate',
  alphaNumericCharactersOnly = 'alphaNumericCharactersOnly',
  nonEmojiCharactersOnly = 'nonEmojiCharactersOnly',
  designRuleIdPattern = 'designRuleIdPattern',
  invalidInteger = 'invalidInteger',
  unique = 'unique',
  required = 'required',
}

export const DEFAULT_ENTRY_LIMITS: readonly number[] = Object.freeze([5, 10, 20]);
export const LARGE_ENTRY_LIMITS: readonly number[] = Object.freeze([10, 20, 50, 100]);
export const DEFAULT_PAGINATION_MAX_SIZE: number = 3;
