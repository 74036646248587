<app-modal-container>
  <div class="modal-header-content">
    {{ headerKey | translate }}
  </div>
  <div class="modal-body-content">
    <div class="row">
      <div class="col-12">
        <p class="body-content-heading">{{ subtitleKey | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-black">
        {{ bodyKey | translate }}
      </div>
    </div>

    <ng-container *ngIf="showConfirmationCheckbox">
      <div class="row mt-4">
        <div class="col-12 d-flex align-items-center">
          <input type="checkbox" [(ngModel)]="confirmed" id="confirmation" />
          <label role="checkbox" for="confirmation" class="ms-2">
            {{ confirmationPromptKey | translate }}
          </label>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer-content">
    <div class="row justify-content-between">
      <div class="col-auto">
        <button id="cancel" class="btn btn-outline-primary" (click)="cancel()">
          {{ 'form_cancel' | translate }}
        </button>
      </div>
      <div class="col-auto">
        <button id="action" class="btn btn-primary" [disabled]="showConfirmationCheckbox && !confirmed" (click)="submit()">
          {{ actionBtnKey | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
