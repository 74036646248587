import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import {
  ICreateTenantRequest,
  ITenant,
  ITenantsResponse,
  IEditTenantRequest,
  ITenantSsoConfig,
  ITenantUser,
  IAssignAcademyRolesRequest,
  IBatchUserIdRequest,
  ITenantInfoWithPackageInfo,
  ITenantWithPackageId,
  ITenantDigitalPropertyLimitState,
  ITenantScimConfig,
  ITenantDigitalPropertyAutomatedFlowLimit,
  ITenantDigitalPropertyUserWaySiteState,
} from '../../../../shared/interfaces/tenant.interface';
import { IWorkspaceWithDpCount } from '../../../../shared/interfaces/workspace.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { Api, ApiQueryOption } from '../../../../shared/constants/api';
import { IScannableDocumentPdfPagesTestStateResponse } from '../../../../shared/interfaces/scannable-document.interface';
import { AcademyUserRole } from '../../../../shared/constants/academy-user';
import { ITenantMetrics } from '../../../../shared/interfaces/tenant-metrics.interface';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import {
  IGetDigitalPropertyMetricsSnapshotsQuery,
  IPaginatedDigitalPropertyMetricsList,
} from '../../../../shared/interfaces/digital-property-metrics.interface';
import { $tenant } from '../../../../shared/constants/tenant';
import { IOrganizationReportResponse } from '../../../../shared/interfaces/async-report.interface';
import { FileExtension } from '../../../../shared/constants/file-extension';
import { DashboardDataFrequency } from '../../../../shared/interfaces/ws-dashboard.interface';
import { IUserWaySSOLink } from '../../../../shared/interfaces/userway-integration.interface';

@Injectable({ providedIn: 'root' })
export class TenantApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /admin/tenants/:tenantId
  public getTenant(tenantId: string): Observable<ITenantWithPackageId> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).getApiUrl();
    return this.httpClient.get<ITenantWithPackageId>(url);
  }

  // GET /tenants/:tenantId
  public getTenantFromTenantedScope(tenantId: string): Observable<ITenantInfoWithPackageInfo> {
    const url: string = this.restBuilder.create().tenant(tenantId).getApiUrl();
    return this.httpClient.get<ITenantInfoWithPackageInfo>(url);
  }

  // GET /tenants
  public getUserTenantsFromTenantedScope(): Observable<ITenant[]> {
    const url: string = this.restBuilder.create().tenant().getApiUrl();
    return this.httpClient.get<ITenant[]>(url);
  }

  // PUT /admin/tenants/:tenantId
  public updateTenant(tenantId: string, updateRequest: IEditTenantRequest): Observable<ITenant> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).getApiUrl();
    return this.httpClient.put<ITenant>(url, updateRequest);
  }

  // GET /admin/tenants/:tenantId/workspaces
  public getWorkspaces(tenantId: string, searchString?: string): Observable<IWorkspaceWithDpCount[]> {
    const urlBuilder: RestBuilder = this.restBuilder.create().admin().tenant(tenantId).workspaces();
    if (!SharedCommonUtility.isNullish(searchString)) {
      urlBuilder.query(
        new HttpParams({
          fromObject: {
            [Api.clientSearchTerm]: searchString,
          },
        }),
      );
    }
    return this.httpClient.get<IWorkspaceWithDpCount[]>(urlBuilder.getApiUrl());
  }

  // GET /admin/tenants
  public getTenants(
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<ITenantsResponse> {
    const url: string = this.restBuilder.create().admin().tenant().getApiUrl();
    const httpParams: HttpParams = this.buildGetTenantsHttpParams(skip, limit, term, sortBy, direction);

    return this.httpClient.get<ITenantsResponse>(url, { params: httpParams });
  }

  // GET /admin/tenants/my
  public getMyTenants(
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<ITenantsResponse> {
    const url: string = this.restBuilder.create().admin().tenant().my().getApiUrl();
    const httpParams: HttpParams = this.buildGetTenantsHttpParams(skip, limit, term, sortBy, direction);

    return this.httpClient.get<ITenantsResponse>(url, { params: httpParams });
  }

  private buildGetTenantsHttpParams(
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): HttpParams {
    const params: { [param: string]: any } = {};

    if (typeof limit === 'number') {
      params[ApiQueryOption.limit] = String(limit);
    }

    if (typeof skip === 'number') {
      params[ApiQueryOption.skip] = String(skip);
    }

    if (typeof sortBy === 'string') {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if (typeof direction === 'string') {
      params[ApiQueryOption.direction] = direction;
    }

    if (term?.length > 0) {
      params[ApiQueryOption.term] = window.encodeURIComponent(term.trim());
    }

    return new HttpParams({ fromObject: params });
  }

  // POST /admin/tenants
  public createTenant(createRequest: ICreateTenantRequest): Observable<ITenant> {
    const url: string = this.restBuilder.create().admin().tenant().getApiUrl();

    return this.httpClient.post<ITenant>(url, createRequest);
  }

  // PUT /admin/tenants/:tenantId/sso-config
  public setSsoConfig(tenantId: string, ssoConfig: ITenantSsoConfig): Observable<void> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).ssoConfig().getApiUrl();
    return this.httpClient.put<void>(url, ssoConfig);
  }

  // DELETE /admin/tenants/:tenantId/sso-config?reason=Reason to delete
  public removeSsoConfig(tenantId: string, reason: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).ssoConfig().getApiUrl();
    return this.httpClient.delete<void>(url, { params: { reason } });
  }

  // GET /admin/tenants/:tenantId/pdf-pages-test-state
  public getPdfPagesTestState(tenantId: string): Observable<IScannableDocumentPdfPagesTestStateResponse> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).pdfPagesTestState().getApiUrl();
    return this.httpClient.get<IScannableDocumentPdfPagesTestStateResponse>(url);
  }

  // GET /admin/tenants/:tenantId/users
  public getTenantUsers(tenantId: string): Observable<ITenantUser[]> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).users().getApiUrl();
    return this.httpClient.get<ITenantUser[]>(url);
  }

  // PUT /admin/tenants/:tenantId/users/academy/activate
  public activateUserInAcademy(tenantId: string, userId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).users().academy().activate().getApiUrl();
    return this.httpClient.put<void>(url, { userId } as IBatchUserIdRequest);
  }

  // PUT /admin/tenants/:tenantId/users/academy/deactivate
  public deactivateUserInAcademy(tenantId: string, userId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).users().academy().deactivate().getApiUrl();
    return this.httpClient.put<void>(url, { userId } as IBatchUserIdRequest);
  }

  // PUT /admin/tenants/:tenantId/users/academy/roles
  public setUserRolesInAcademy(tenantId: string, userId: string, roles: AcademyUserRole[]): Observable<void> {
    const url: string = this.restBuilder.create().admin().tenant(tenantId).users().academy().roles().getApiUrl();
    return this.httpClient.put<void>(url, { userId, roles } as IAssignAcademyRolesRequest);
  }

  // GET /tenants/:tenantId/digital-property-limit-state
  public getDigitalPropertyLimitState(tenantId: string): Observable<ITenantDigitalPropertyLimitState | null> {
    const url: string = this.restBuilder.create().tenant(tenantId).digitalPropertyLimitState().getApiUrl();
    return this.httpClient.get<ITenantDigitalPropertyLimitState>(url);
  }

  public getDigitalPropertyAutomatedFlowLimit(): Observable<ITenantDigitalPropertyAutomatedFlowLimit> {
    const url: string = this.restBuilder.create().tenant().current().digitalPropertyAutomatedFlowLimit().getApiUrl();
    return this.httpClient.get<ITenantDigitalPropertyAutomatedFlowLimit>(url);
  }

  // GET /tenants/:tenantId/userway_site_limit_state
  public getDigitalPropertyUserWaySiteLimitState(): Observable<ITenantDigitalPropertyUserWaySiteState | null> {
    const url: string = this.restBuilder.create().tenant().current().digitalPropertyUserWaySiteLimitState().getApiUrl();
    return this.httpClient.get<ITenantDigitalPropertyUserWaySiteState>(url);
  }

  // GET /tenants/:tenantId/dashboard/metrics
  public getTenantMetrics(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    frequency: DashboardDataFrequency,
  ): Observable<ITenantMetrics[]> {
    const url: string = this.restBuilder.create().tenant(tenantId).dashboard().metrics().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: { [ApiQueryOption.toolName]: toolName, [ApiQueryOption.frequency]: frequency },
    });
    return this.httpClient.get<ITenantMetrics[]>(url, { params: httpParams });
  }

  // GET /tenants/:tenantId/dashboard/digital-property-metrics
  public getDigitalPropertyMetricsSnapshots(
    tenantId: string,
    params: IGetDigitalPropertyMetricsSnapshotsQuery,
  ): Observable<IPaginatedDigitalPropertyMetricsList> {
    const url: string = this.restBuilder.create().tenant(tenantId).dashboard().digitalPropertyMetrics().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.httpClient.get<IPaginatedDigitalPropertyMetricsList>(url, { params: httpParams });
  }

  // PUT /tenants/current/cloudsmith-token
  public getOrCreateTenantEntitlementToken(): Observable<Pick<ITenant, $tenant.cloudsmithToken>> {
    const url: string = this.restBuilder.create().tenant().current().cloudsmithToken().getApiUrl();
    return this.httpClient.put<Pick<ITenant, $tenant.cloudsmithToken>>(url, {});
  }

  // GET /tenants/current/cloudsmith-token
  public getTenantEntitlementToken(): Observable<Pick<ITenant, $tenant.cloudsmithToken>> {
    const url: string = this.restBuilder.create().tenant().current().cloudsmithToken().getApiUrl();
    return this.httpClient.get<Pick<ITenant, $tenant.cloudsmithToken>>(url);
  }

  // GET /tenants/:tenantId/dashboard/report
  public downloadOrganizationReport(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    fileExtension: FileExtension,
  ): Observable<IOrganizationReportResponse> {
    const url: string = this.restBuilder.create().tenant(tenantId).dashboard().report().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: {
        [ApiQueryOption.toolName]: toolName,
        [ApiQueryOption.format]: fileExtension,
      },
    });
    return this.httpClient.get<IOrganizationReportResponse>(url, { params: httpParams });
  }

  // POST /tenants/:tenantId/dashboard/report
  public generateOrganizationReport(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    fileExtension: FileExtension,
  ): Observable<IOrganizationReportResponse> {
    const url: string = this.restBuilder.create().tenant(tenantId).dashboard().report().getApiUrl();
    return this.httpClient.post<IOrganizationReportResponse>(url, {
      [ApiQueryOption.toolName]: toolName,
      [ApiQueryOption.format]: fileExtension,
    });
  }

  // GET /tenants/current/scim-config
  public getScimConfig(): Observable<ITenantScimConfig> {
    const url: string = this.restBuilder.create().tenant().current().scimConfig().getApiUrl();

    return this.httpClient.get<ITenantScimConfig>(url);
  }

  // POST /tenants/current/scim-config
  public setScimConfig(config: ITenantScimConfig): Observable<ITenantScimConfig> {
    const url: string = this.restBuilder.create().tenant().current().scimConfig().getApiUrl();

    return this.httpClient.put<ITenantScimConfig>(url, config);
  }

  // GET /tenants/current/userway-sso-link
  public generateUserWaySSOLink(): Observable<IUserWaySSOLink> {
    const url: string = this.restBuilder.create().tenant().current().userWaySSOLink().getApiUrl();

    return this.httpClient.get<IUserWaySSOLink>(url);
  }
}
