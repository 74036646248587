import { Component, ElementRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import { FormService } from '../../../../services/form.service';
import { TranslateService } from '../../../../translate/translate.service';
import { HealthcheckService } from '../../../../services/healthcheck.service';
import { ErrorMessageService } from '../../../../services/error-message.service';
import { redirectUrl } from '../../../../shared/constants';
import { Api } from '../../../../../../shared/constants/api';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';

enum FormField {
  'organizationUrl' = 'organizationUrl',
}

@Component({
  selector: 'app-tenant-login-redirect',
  templateUrl: './tenant-login-redirect.component.html',
  styleUrl: './tenant-login-redirect.component.scss',
})
export class TenantLoginRedirectComponent {
  protected readonly FormField: typeof FormField = FormField;

  public formFieldErrors: Partial<Record<FormField, string>>;
  public formFieldsLabel: Record<FormField, string>;
  public form: UntypedFormGroup;

  private readonly returnTo: string | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private translateService: TranslateService,
    private healthcheckService: HealthcheckService,
    private errorMessageService: ErrorMessageService,
    private element: ElementRef<Element>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.formFieldErrors = {};
    this.formFieldsLabel = {
      [FormField.organizationUrl]: 'organization_url',
    };
    this.form = this.formBuilder.group({
      [FormField.organizationUrl]: this.formBuilder.control('', {
        validators: [CustomValidators.required, CustomValidators.domainValidator],
        asyncValidators: this.healthcheckService.organizationURLAsyncValidator,
      }),
    });
    this.returnTo = this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo];
  }

  public checkValidity(): boolean {
    if (this.form.valid) {
      this.formFieldErrors = {};
      return true;
    }

    if (this.form.get(FormField.organizationUrl).hasError('invalidOrgURL')) {
      this.formFieldErrors[FormField.organizationUrl] = this.translateService.instant('non-existent-website-error');
    } else {
      this.formFieldErrors[FormField.organizationUrl] = this.formService.getErrorMessageForField(
        this.form.get(FormField.organizationUrl),
        FormField.organizationUrl,
        '',
        '',
        {
          required: this.translateService.instant('enter_a_url'),
        },
      );
    }
    this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);

    return false;
  }

  public onSubmit(): void {
    if (!this.checkValidity()) {
      return;
    }

    const orgUrl: string = this.form.get(FormField.organizationUrl).value?.trim();
    const [, orgDomain] = SharedCommonUtility.getProtocolHostPortAndPath(orgUrl);

    let redirectionLoginUrl: string = `https://${orgDomain}/${Api.login}`;
    if (SharedCommonUtility.notNullishOrEmpty(this.returnTo)) {
      redirectionLoginUrl += `?${redirectUrl.returnTo}=${this.returnTo}`;
    }
    window.open(redirectionLoginUrl, '_self');
  }
}
