import { Component, ViewChild } from '@angular/core';
import { IModal } from '../modal.interface';
import { ModalContainerComponent } from '../modal-container.component';

@Component({
  selector: 'app-checkbox-confirmation-modal',
  templateUrl: './checkbox-confirmation-modal.component.html',
  styleUrls: ['./checkbox-confirmation-modal.component.scss'],
})
export class CheckboxConfirmationModalComponent implements IModal {
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  public headerKey: string = '';
  public subtitleKey: string = '';
  public bodyKey: string = '';
  public confirmationPromptKey: string = '';
  public actionBtnKey: string = '';
  public confirmed: boolean = false;
  public cancelled: boolean = true;
  public showConfirmationCheckbox: boolean = true;

  constructor() {}

  public init(
    headerKey: string,
    subtitleKey: string,
    bodyKey: string,
    confirmationPromptKey: string,
    actionBtnKey: string,
    showConfirmationCheckbox: boolean = true,
  ): void {
    this.headerKey = headerKey;
    this.subtitleKey = subtitleKey;
    this.bodyKey = bodyKey;
    this.confirmationPromptKey = confirmationPromptKey;
    this.actionBtnKey = actionBtnKey;
    this.showConfirmationCheckbox = showConfirmationCheckbox;
  }

  public cancel(): void {
    this.cancelled = true;
    this.confirmed = false;
    this.container.closeModal();
  }

  public submit(): void {
    this.cancelled = false;
    this.confirmed = true;
    this.container.closeModal();
  }
}
