<div class="d-flex flex-column w-100">
  <label *ngIf="label" [for]="controlId">{{ label }}</label>
  <div class="search-input-wrap d-flex align-items-center w-100">
    <svg aria-hidden="true">
      <use xlink:href="#search-input-prefix"></use>
    </svg>
    <input
      [id]="controlId"
      #textbox
      type="text"
      class="form-element d-flex flex-fill"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (keydown.enter)="propagateChanges($event)"
      appCtrlKey
    />
    <svg
      *ngIf="value?.length > 0"
      class="search-input-clear-button"
      role="button"
      tabindex="0"
      (click)="clear()"
      (keydown.enter)="clear()"
      [attr.aria-label]="'clear_text' | translate"
      [class.search-input-clear-button--full-right]="stackSearchButtonOnMobile"
    >
      <use xlink:href="#search-input-suffix"></use>
    </svg>
    <button
      type="button"
      class="btn btn-primary fw-bold"
      [ngClass]="{ 'd-none': stackSearchButtonOnMobile, 'd-sm-block': stackSearchButtonOnMobile }"
      (click)="propagateChanges()"
    >
      {{ 'form_search' | translate }}
    </button>
  </div>

  <button
    *ngIf="stackSearchButtonOnMobile"
    class="btn btn-primary fw-bold mt-2 d-sm-none d-block search-input-submit-button--stacked"
    (click)="propagateChanges()"
  >
    {{ 'form_search' | translate }}
  </button>
</div>
