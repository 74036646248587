import {
  manualAuditStatus,
  scanAuthenticationType,
  scanFindingArchivalState,
  scanningType,
  ScanOrigin,
  scanStatus,
  standardNameAndLevel,
  wcagNameAndLevel,
} from '../constants/scanning';
import { IAudits } from './audits.interface';
import {
  $automatedUserFlowError,
  $brokenLinkData,
  $pageWithComponents,
  $rerunScan,
  $scan,
  $scanAutomatedOptions,
  $scanHeaders,
  $scanScope,
  $scanSearch,
  $scanMobile,
  $scanMobilePlatformName,
  $scanMobileDeviceType,
} from '../constants/scan';
import { $urlScan } from '../constants/url-scan';
import { IUser } from './user.interface';
import { IScanSchedule } from './scan-schedule.interface';
import { IGlobalError } from './error.interface';
import { ISecureWebAuthentication } from './scan-secuire-web-authentication.interface';
import { IFile } from './file.interface';
import { IScanViewport } from './scan-viewport.interface';
import { IDigitalProperty } from './digital-property.interface';
import { IUploads } from './uploads.interface';
import { AuditTypes } from '../constants/audit-types';
import { IScanTag } from './scan-tag.interface';
import { AccessibilityAuditToolNames } from '../constants/audit-tool';
import { IWorkspace } from './workspace.interface';
import { IDiscoveredScannableDocument } from './scannable-document.interface';
import { CrawlingStatus } from './crawling-status.interface';
import { ApiQueryOption } from '../constants/api';
import { IdStringArray, IdString } from './id-string.interface';
import { UserFlowActionType } from '../constants/automated-user-flow';
import { IPage } from './page.interface';
import { ITestGuide } from './test-guide.interface';
import { IMatchComponent } from './match-component.interface';

export enum ExcludePagesOperation {
  contains = 'contains',
  does_not_contain = 'does_not_contain',
  ends_with = 'ends_with',
  starts_with = 'starts_with',
}

export interface SkippedPageData {
  url: string;
  error: any;
  extras?: any;
}

export interface BrokenLinkData {
  [$brokenLinkData.url]: string;
  [$brokenLinkData.responseCode]: number;
}

export interface ProcessingUrlData {
  [$urlScan.url]: string;
  [$urlScan.crawlDeepLevel]: number;
  [$urlScan.scanJobs]: { _id: string; name: string; status: string; scanErrors: IGlobalError[] }[];
}

export interface IScan {
  _id?: any;
  [$scan.auditsId]: string;
  [$scan.audits]?: IAudits;
  [$scan.authPassword]?: string;
  [$scan.authUser]?: string;
  [$scan.authType]?: scanAuthenticationType;
  [$scan.brokenLinks]?: BrokenLinkData[];
  [$scan.scannableDocuments]?: IDiscoveredScannableDocument[];
  [$scan.discoverScannableDocuments]?: boolean;
  [$scan.conformanceLevel]?: wcagNameAndLevel;
  [$scan.contentType]: string;
  [$scan.context]?: string;
  [$scan.contextDescription]?: string;
  [$scan.crawled]: boolean;
  [$scan.crawlDeepLevel]: number | undefined;
  [$scan.createdAtTimezoneOffset]: number | string;
  [$scan.customFiles]: IFile[] | undefined;
  [$scan.customLinks]?: string[];
  [$scan.digitalProperty]: string | IDigitalProperty;
  [$scan.headers]: IScanHeader[] | undefined;
  [$scan.excludeUrls]?: string[];
  [$scan.excludeUrlOperations]?: ExcludePagesOperation[];
  [$scan.errorReason]?: string | undefined;
  [$scan.findingsArchival]?: scanFindingArchivalState | undefined;
  [$scan.findingsArchivalDate]?: Date | string | undefined;
  [$scan.includeUrls]: string[] | undefined;
  [$scan.languageForScanning]: string;
  [$scan.limitCrawlingPagesTo]: number | undefined;
  [$scan.manualUploadFiles]?: (string | IUploads)[];
  [$scan.manualAuditStatus]?: manualAuditStatus;
  [$scan.pageTitle]: string;
  [$scan.processingStartedAt]: Date | string;
  [$scan.processingEndedAt]: Date | string;
  [$scan.resourceType]: string;
  [$scan.scanningType]: scanningType;
  [$scan.sendEmail]: boolean;
  [$scan.scheduledAt]: string | Date;
  [$scan.scheduleId]: string | IScanSchedule;
  [$scan.secureWebAuthentication]?: ISecureWebAuthentication | undefined;
  [$scan.skipHashes]: boolean;
  [$scan.skipQueryParams]: boolean;
  [$scan.skippedPages]?: SkippedPageData[];
  [$scan.selectedAudits]?: AuditTypes[];
  [$scan.status]: scanStatus;
  [$scan.crawlingStatus]?: CrawlingStatus;
  [$scan.tag]?: IScanTag | string;
  [$scan.title]: string;
  [$scan.url]: string;
  [$scan.totalCrawledPages]: number;
  [$scan.totalScannedPages]: number;
  [$scan.urlResolved]: string | undefined;
  [$scan.user]: string | IUser;
  [$scan.previousScans]: string[] | IScan[];
  [$scan.pagesWithErrors]?: number;
  [$scan.workspace]: string | IWorkspace;
  [$scan.userAgent]: string;
  [$scan.viewport]?: IScanViewport;
  [$scan.isArchived]?: boolean;
  [$scan.archivedBy]?: IUser | string;
  [$scan.createdAt]: string;
  [$scan.updatedAt]: string;
  [$scan.scanOrigin]: ScanOrigin;
  [$scan.emailsToNotify]?: string[];
  [$scan.handlePopupsSelector]?: string;
  [$scan.includeIframes]?: boolean;
  [$scan.preScanFlowId]?: string;
}

export interface IScanWithScore extends IScan {
  [$scan.score]?: number;
}

export interface IAutomatedUserFlowError {
  [$automatedUserFlowError.actionId]: string;
  [$automatedUserFlowError.actionType]: UserFlowActionType;
  [$automatedUserFlowError.actionValue]: string;
  [$automatedUserFlowError.errorMessage]: string;
}

export interface IAutomatedUserFlowDropdownError extends IAutomatedUserFlowError {
  [$automatedUserFlowError.actionCssSelector]: string;
}

export interface IAutomatedUserFlowChangeInputError extends IAutomatedUserFlowError {
  [$automatedUserFlowError.actionCssSelector]: string;
}

export interface IAutomatedUserFlowScan extends IScan {
  [$scan.scanningType]: scanningType.automatedUserFlow;
  [$scan.automatedUserFlowId]: string;
  [$scan.automatedUserFlowError]?: IAutomatedUserFlowError | IAutomatedUserFlowDropdownError;
}

export interface IManualScan extends IScan {
  [$scan.scanningType]: scanningType.manualAudit;
  [$scan.origin]: Origin;
  [$scan.scope]?: IScanScope;
}

export interface IMobileScan extends IScan {
  [$scan.mobile]: IMobileScanDetails;
}

export interface IMobileScanDetails {
  [$scanMobile.platformName]: $scanMobilePlatformName;
  [$scanMobile.platformVersion]: string;
  [$scanMobile.deviceManufacturer]?: string;
  [$scanMobile.deviceModel]: string;
  [$scanMobile.deviceName]: string;
  [$scanMobile.deviceType]: $scanMobileDeviceType;
  [$scanMobile.appIdentifier]?: string;
  [$scanMobile.appVersion]?: string;
  [$scanMobile.continuumSdkVersion]: string;
  [$scanMobile.appiumAutomationName]?: string;
}

export interface IManualScanEdit extends IManualScan {
  testingStarted: boolean;
}

export interface IGetScansServerResponse {
  _total: number;
  scans: IScanWithScore[];
}

export interface IScreenshotDetail {
  title: string;
  base64Data: string;
  filter: string;
}

export interface IActiveScan {
  _id: string;
  [$scan.user]: string;
  [$scan.audits]?: IAudits;
  [$scan.status]: scanStatus;
  [$scan.totalScannedPages]: number;
  [$scan.totalCrawledPages]: number;
  [$scan.limitCrawlingPagesTo]?: number;
  [$scan.title]: string;
  [$scan.urlResolved]: string;
  [$scan.processingStartedAt]: string;
  [$scan.scanningType]: scanningType;
  [$scan.workspace]: string | IWorkspace;
  [$scan.digitalProperty]: string;
  [$scan.selectedAudits]: string[];
  [$scan.viewport]?: IScanViewport;
}

export type GetScansScanningType = Exclude<scanningType, scanningType.manualAudit>;

export type GetScansScanningTypeQuery = GetScansScanningType | GetScansScanningType[];
export type ScanStatusQuery = scanStatus | scanStatus[];
export type GetScansTagQuery = IdString | IdStringArray;

export interface IGetScansQuery {
  [$scanSearch.createdAt]?: string;
  [$scanSearch.entriesAmount]?: string;
  [$scanSearch.limit]?: string;
  [$scanSearch.scanningType]?: GetScansScanningTypeQuery;
  [$scanSearch.searchTerm]?: string;
  [$scanSearch.skip]?: string;
  [$scanSearch.status]?: ScanStatusQuery;
  [$scanSearch.url]?: string;
  [$scanSearch.tag]?: GetScansTagQuery;
  [$scanSearch.title]?: string;
  [$scanSearch.tool]?: AccessibilityAuditToolNames;
  [$scanSearch.mobileAppVersion]?: string;

  [ApiQueryOption.sortBy]?: string;
}

export interface ISendEmailsRequest {
  /**
   * @items.type string
   * @items.format email
   */
  [$scan.emails]: string[];
  auditTool?: AccessibilityAuditToolNames;
}

export interface IQuickScanOptions {
  [$scanAutomatedOptions.scanningType]: scanningType;
  [$scanAutomatedOptions.title]?: string;
  [$scanAutomatedOptions.viewport]: IScanViewport;
  [$scanAutomatedOptions.conformanceLevel]: standardNameAndLevel;
  [$scanAutomatedOptions.url]: string;
  [$scanAutomatedOptions.tag]: string;
}

export interface IRerunScanForPagesOptions {
  [$rerunScan.pages]: string[];
}

export interface ISupplementalScanOption {
  [$scan.url]: string;
}

export interface IScanHeader {
  [$scanHeaders.name]: string;
  [$scanHeaders.value]: string;
}

export interface IUpdateScanTitleBody {
  [$scan._id]: IdString;
  [$scan.title]: string;
}

export interface IScanScope {
  [$scanScope.testGuideId]: string;
  [$scanScope.testGuide]?: ITestGuide;
  [$scanScope.pages]: IPageWithComponents[];
}

export enum Origin {
  staff = 'staff',
  client = 'client',
}

export interface IPageWithComponents {
  [$pageWithComponents.pageId]: string;
  [$pageWithComponents.page]?: IPage;
  [$pageWithComponents.componentIds]: string[];
  [$pageWithComponents.components]?: IMatchComponent[];
}
