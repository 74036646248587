import { AuditTypes } from './audit-types';
import { scanningJobType } from './scanning';

export enum $auditTool {
  _id = '_id',
  name = 'name',
  version = 'version',
}

export enum AuditToolNames {
  aslint = 'aslint',
  axe = 'axe',
  ibm = 'ibm',
  manual = 'manual',
  screenshot = 'screenshot',
  wave = 'wave',
  access_engine = 'access-engine',
}

export enum AccessibilityAuditToolNames {
  aslint = 'aslint',
  axe = 'axe',
  ibm = 'ibm',
  wave = 'wave',
  access_engine = 'access-engine',
}
/**
 * This regex matches the access engine shadow dom separator pattern used in UEls.
 * It only matches single pipes "|", it will not match double pipes "||".
 */
export const ACCESS_ENGINE_SHADOW_DOM_SEPARATOR_MATCHER: RegExp = new RegExp(/(?<!\|)\|(?!\|)/);
/**
 * This regex matches the iframe separator pattern used in UELs.
 * It matches double pipes "||".
 */
export const IFRAME_DOM_SEPARATOR_MATCHER: RegExp = new RegExp(/\|\|/);

export const deprecatedToolNames: AccessibilityAuditToolNames[] = [AccessibilityAuditToolNames.aslint];

export const activeAccessibilityToolNames: Readonly<AccessibilityAuditToolNames[]> = Object.freeze(
  Object.values(AccessibilityAuditToolNames).filter((tool: AccessibilityAuditToolNames) => {
    return !deprecatedToolNames.includes(tool);
  }),
);

export const defaultToolName: AccessibilityAuditToolNames = AccessibilityAuditToolNames.access_engine;

export enum AuditToolCommonNames {
  aslint = 'aslint',
  axe_core = 'axe-core',
  equal_access = 'equal-access',
  wave = 'WAVE',
  access_engine = 'access-engine',
}

export const mapAuditToolCommonNamesToDisplayName: Record<AuditToolCommonNames, string> = Object.freeze({
  [AuditToolCommonNames.access_engine]: 'scan_results_tool_option_access-engine',
  [AuditToolCommonNames.aslint]: 'scan_results_tool_option_aslint',
  [AuditToolCommonNames.axe_core]: 'scan_results_tool_option_axe',
  [AuditToolCommonNames.equal_access]: 'scan_results_tool_option_ibm',
  [AuditToolCommonNames.wave]: 'scan_results_tool_option_wave',
});

export const ToolCommonNameToAuditToolName: Readonly<Record<AuditToolCommonNames, AccessibilityAuditToolNames>> = Object.freeze({
  [AuditToolCommonNames.access_engine]: AccessibilityAuditToolNames.access_engine,
  [AuditToolCommonNames.aslint]: AccessibilityAuditToolNames.aslint,
  [AuditToolCommonNames.axe_core]: AccessibilityAuditToolNames.axe,
  [AuditToolCommonNames.equal_access]: AccessibilityAuditToolNames.ibm,
  [AuditToolCommonNames.wave]: AccessibilityAuditToolNames.wave,
});

export const AuditToolCommonNamesToAuditToolNames: Readonly<Record<AuditToolCommonNames, AuditToolNames>> = Object.freeze({
  [AuditToolCommonNames.access_engine]: AuditToolNames.access_engine,
  [AuditToolCommonNames.aslint]: AuditToolNames.aslint,
  [AuditToolCommonNames.axe_core]: AuditToolNames.axe,
  [AuditToolCommonNames.equal_access]: AuditToolNames.ibm,
  [AuditToolCommonNames.wave]: AuditToolNames.wave,
});

export const AuditToolCommonNamesToAccessibilityAuditToolNames: Readonly<
  Record<AuditToolCommonNames, AccessibilityAuditToolNames>
> = Object.freeze({
  [AuditToolCommonNames.access_engine]: AccessibilityAuditToolNames.access_engine,
  [AuditToolCommonNames.aslint]: AccessibilityAuditToolNames.aslint,
  [AuditToolCommonNames.axe_core]: AccessibilityAuditToolNames.axe,
  [AuditToolCommonNames.equal_access]: AccessibilityAuditToolNames.ibm,
  [AuditToolCommonNames.wave]: AccessibilityAuditToolNames.wave,
});

export const AuditToolCommonNamesToAuditTypes: Readonly<Record<AuditToolCommonNames, AuditTypes>> = {
  [AuditToolCommonNames.access_engine]: AuditTypes.accessibility_access,
  [AuditToolCommonNames.aslint]: AuditTypes.accessibility,
  [AuditToolCommonNames.axe_core]: AuditTypes.accessibility_axe,
  [AuditToolCommonNames.equal_access]: AuditTypes.accessibility_ibm,
  [AuditToolCommonNames.wave]: AuditTypes.accessibility_wave,
};

export const AccessibilityAuditToolNamesToAuditTypes: Readonly<Record<AccessibilityAuditToolNames, AuditTypes>> = Object.freeze({
  [AccessibilityAuditToolNames.access_engine]: AuditTypes.accessibility_access,
  [AccessibilityAuditToolNames.aslint]: AuditTypes.accessibility,
  [AccessibilityAuditToolNames.ibm]: AuditTypes.accessibility_ibm,
  [AccessibilityAuditToolNames.axe]: AuditTypes.accessibility_axe,
  [AccessibilityAuditToolNames.wave]: AuditTypes.accessibility_wave,
});

export const scanningJobTypeToAuditToolCommonName: { [key in keyof typeof scanningJobType]: string } = Object.freeze({
  [scanningJobType.accessibility_access]: AuditToolCommonNames.access_engine,
  [scanningJobType.accessibility]: AuditToolCommonNames.aslint,
  [scanningJobType.accessibility_axe]: AuditToolCommonNames.axe_core,
  [scanningJobType.accessibility_ibm]: AuditToolCommonNames.equal_access,
  [scanningJobType.accessibility_wave]: AuditToolCommonNames.wave,
  [scanningJobType.screenshot]: 'Screenshot',
});

export const scanningJobTypeToAuditToolName: Readonly<Record<scanningJobType, AuditToolNames>> = Object.freeze({
  [scanningJobType.accessibility_access]: AuditToolNames.access_engine,
  [scanningJobType.accessibility]: AuditToolNames.aslint,
  [scanningJobType.accessibility_axe]: AuditToolNames.axe,
  [scanningJobType.accessibility_ibm]: AuditToolNames.ibm,
  [scanningJobType.accessibility_wave]: AuditToolNames.wave,
  [scanningJobType.screenshot]: AuditToolNames.screenshot,
});

export const AuditToolNameToAuditType: Readonly<Partial<Record<AuditToolNames, AuditTypes>>> = Object.freeze({
  [AuditToolNames.access_engine]: AuditTypes.accessibility_access,
  [AuditToolNames.aslint]: AuditTypes.accessibility,
  [AuditToolNames.ibm]: AuditTypes.accessibility_ibm,
  [AuditToolNames.axe]: AuditTypes.accessibility_axe,
  [AuditToolNames.wave]: AuditTypes.accessibility_wave,
  [AuditToolNames.screenshot]: AuditTypes.screenshots,
});

export const AccessibilityAuditToolNameAuditToolName: Readonly<Record<AccessibilityAuditToolNames, AuditToolNames>> =
  Object.freeze({
    [AccessibilityAuditToolNames.access_engine]: AuditToolNames.access_engine,
    [AccessibilityAuditToolNames.aslint]: AuditToolNames.aslint,
    [AccessibilityAuditToolNames.ibm]: AuditToolNames.ibm,
    [AccessibilityAuditToolNames.axe]: AuditToolNames.axe,
    [AccessibilityAuditToolNames.wave]: AuditToolNames.wave,
  });

export const AuditTypeToAuditToolName: Readonly<Partial<Record<AuditTypes, AuditToolNames>>> = Object.freeze({
  [AuditTypes.accessibility]: AuditToolNames.aslint,
  [AuditTypes.accessibility_access]: AuditToolNames.access_engine,
  [AuditTypes.accessibility_ibm]: AuditToolNames.ibm,
  [AuditTypes.accessibility_axe]: AuditToolNames.axe,
  [AuditTypes.accessibility_wave]: AuditToolNames.wave,
  [AuditTypes.screenshots]: AuditToolNames.screenshot,
});

export const AuditTypeToAccessibilityAuditToolName: Readonly<Partial<Record<AuditTypes, AccessibilityAuditToolNames>>> =
  Object.freeze({
    [AuditTypes.accessibility]: AccessibilityAuditToolNames.aslint,
    [AuditTypes.accessibility_access]: AccessibilityAuditToolNames.access_engine,
    [AuditTypes.accessibility_ibm]: AccessibilityAuditToolNames.ibm,
    [AuditTypes.accessibility_axe]: AccessibilityAuditToolNames.axe,
    [AuditTypes.accessibility_wave]: AccessibilityAuditToolNames.wave,
  });

export const AccessibilityAuditToolNameToAuditType: Readonly<Partial<Record<AccessibilityAuditToolNames, AuditTypes>>> =
  Object.freeze({
    [AccessibilityAuditToolNames.access_engine]: AuditTypes.accessibility_access,
    [AccessibilityAuditToolNames.aslint]: AuditTypes.accessibility,
    [AccessibilityAuditToolNames.ibm]: AuditTypes.accessibility_ibm,
    [AccessibilityAuditToolNames.axe]: AuditTypes.accessibility_axe,
    [AccessibilityAuditToolNames.wave]: AuditTypes.accessibility_wave,
  });

export const AuditToolNameToToolCommonName: Readonly<Record<AccessibilityAuditToolNames, AuditToolCommonNames>> = Object.freeze({
  [AccessibilityAuditToolNames.aslint]: AuditToolCommonNames.aslint,
  [AccessibilityAuditToolNames.axe]: AuditToolCommonNames.axe_core,
  [AccessibilityAuditToolNames.ibm]: AuditToolCommonNames.equal_access,
  [AccessibilityAuditToolNames.wave]: AuditToolCommonNames.wave,
  [AccessibilityAuditToolNames.access_engine]: AuditToolCommonNames.access_engine,
});
